.inven-first-secct-adding-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin-top: 18px;
    flex-wrap: wrap;
}

.invent-adding-product-alert-text-p {
    margin: 9px 15px 21px 15px;
    display: flex;
    align-items: center;

    img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }
}