.lock-screen-alert {
    width: 100%;
    min-height: 100vh;
    background:rgba(0,0,0,0.3);
    position: absolute;
    z-index: 9;
}

.alert-genaral-card-auth {
    background: white;
    color: black;
    width: 250px;
    z-index: 10;
    position: absolute;
    border-radius: 8px;
    top: 18%;
    left: calc(50% - 146px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);

    max-height: calc(85vh - 100px);

    overflow: hidden;  
    overflow-y: scroll;
}

.alert-genaral-card-auth.active {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
    transition: 200ms ease;
}

.alert-genaral-card-auth.inactive {
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0.5);
    transition: 200ms ease;
}

.alert-genaral-card-auth::-webkit-scrollbar {
    width: 0.4em;
}

.alert-genaral-card-auth::-webkit-scrollbar-thumb {
    background: #42424383;
    border-radius: 100vw;
}

.alert-general-despcrition-auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 120px;
    text-align: center;

    .alert-general-despcrition-p {
        font-size: 15px;
        margin: 10px;
        margin-top: 35px;
        margin-bottom: 25px;
    }
}

.alert-general-despcrition-email {
    font-size: 13px;
}

.alert-general-despcrition-pic-div {
    width: 80px;
    height: 80px;
    margin-top: 15px;
    margin-bottom: 15px;

    .alert-general-despcrition-pic-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100px;
    }
}

.alert-general-card-img {
    width: 70px;
    height: 70px;
}

.alert-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 60px;
    min-height: 60px;
    padding-top: 15px;
}

.alert-button-iten {
    width: 90px;
    padding: 12px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;
}

.alert-button-iten-yes {
    width: 100px;
    padding: 12px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert-button-iten-cancel {
    width: 100px;
    padding: 12px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-user-profile-yes-img-big-cont {
    display: flex;
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.aut-use-pro-yes-img-big-con-div {
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
}