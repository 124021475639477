.side-menu-desktop-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 65px);
    overflow: hidden;
    overflow-y: scroll;
}

.side-menu-desktop-container.maximized {
    min-width: 270px;
    max-width: 75%;
}

.side-menu-desktop-container.minimized {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
}

.side-menu-options-space {
    flex-grow: 4;
}

.side-menu-options-desktop {
    margin-top: 10px;

    .side-menu-item-desktop {
        list-style: none;  
        border-radius: 10px;
        margin: 10px;
        
        .side-menu-form-desktop {

            .side-menu-form-input-container-desktop {
                padding: 5px;
                padding-left: 10px;
                display: flex;
                align-items: center;
            }
            
            input {
                cursor: pointer;
                padding: 10px;
                width: 20px;
                height: 20px;
            }

            label {
                cursor: pointer;
                font-size: 16px;
                padding: 10px 50px 10px 10px;
            }
        }
    }

    .side-menu-item-desktop.light {
        background: var(--light-tertiary-bg);
        color: black;
    }
    
    .side-menu-item-desktop.dark {
        background: var(--dark-tertiary-bg);
        color: white;
    }
    .side-menu-item-desktop.closed {
        background: transparent;
    }

    .side-menu-form-desktop.open {
        opacity: 1;
        height: auto;
    }

    .side-menu-form-desktop.closed {
        transition: opacity 1s ease-out;
        opacity: 0;
        height: 0;
        overflow: hidden;
    }
}

.change-menu-size-container {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    width: 100%;
}

.change-menu-size-button {
    display: flex;
    background: transparent;
    cursor: pointer;
    border: none;
    align-items: center;
    width: 95%;
    border-radius: 10px;
    justify-content: space-between;
    height: 50px;
    margin: 10px;

    p {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        padding-left: 20px;
    }
}

.change-menu-size-button-dark {
    color: white;
}

.change-menu-size-button-light {
    color: black;
}

.change-menu-size-button-light:hover {
    background-color: var(--light-tertiary-bg);
}

.change-menu-size-button-dark:hover {
    background-color: var(--dark-tertiary-bg);
}

.change-menu-size-img {
    width: 16px;
    height: 16px;
    margin: 10px;
    margin-left: 20px;
}

.change-menu-size-img-minimized {
    transform: rotate(270deg);
    transition: all 0.3s ease-in-out;
}


.change-menu-size-img-maximized {
    transform: rotate(90deg);
    transition: all 0.3s ease-in-out;
}
