.picker-date-lock-screen {
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    z-index: 3;
}

.picker-date-genaral-card {
    color: black;
    width: 340px;
    z-index: 4;
    position: absolute;
    border-radius: 8px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    max-height: calc(100vh - 105px);
    overflow: hidden;  
    overflow-y: scroll;
}

.picker-date-genaral-card.active {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
    transition: 200ms ease;
}

.picker-date-genaral-card.inactive {
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0.5);
    transition: 200ms ease;
}

.picker-date-genaral-card::-webkit-scrollbar {
    width: 0.4em;
}

.picker-date-genaral-card::-webkit-scrollbar-thumb {
    background: #42424383;
    border-radius: 100vw;
}

.picker-date-general-despcrition {
    display: flex;
    flex-direction: column;
    width: 100%;

    h4 {
        margin: 10px;
    }
}

.picker-date-select-inputs {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 5px;
}

.picker-date-select-container-current {
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
    max-width: 280px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        white-space: nowrap;
    }
}

.picker-date-select-container-current-arrow-img {
    width: 14px;
    height: 14px;
    margin: 5px 5px 5px 10px;
}

.picker-date-options-cont {
    min-height: 180px;
    width: 100%;
    min-height: 286px;
}

.picker-date-today-cont {
    display: flex;
    width: 100%;
    height: 60px;

    button {
        padding: 3px 10px 1px 10px;
        border-radius: 10px;
        margin: 15px;
        cursor: pointer;

        img {
            width: 20px;
            height: 20px;
        }

        p {
            margin-top: -5px;
        }
    }
}

.picker-date-today-second-cont {
    width: 100%;
    display: flex;
    justify-content: center;
}

.picker-date-bottons-cont {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-top: 10px;
}

.date-picker-botton-dark {
    background-color: #4d4d4d; 
    color: white;
}

.date-picker-botton-light {
    background-color: var(--light-secundary-bg);
    color: black;
}

.date-picker-botton-dark:hover {
    background-color: #3f3f3f; 
    color: white;
}

.date-picker-botton-light:hover {
    background-color: var(--light-tertiary-bg);
    color: black;
}

.picker-date-day-option {
    padding: 2px;
    height: 35px;
    width: 42px;
    margin-bottom: 3px;
    border-radius: 50px;
    border: none;
    cursor: pointer;
}

.picker-date-day-option-active {
    background-color: #5090d4cb;
    color: white;
    font-weight: bold;
}

.picker-date-day-option-inactive {
    background-color: transparent;
}

.picker-date-day-option-dark {
    color: white;
}

.picker-date-day-option-light {
    color: black;
}

.picker-date-day-option-dark:hover {
    background-color: #4d4d4d;  
    color: white;
}

.picker-date-day-option-light:hover {
    background-color: var(--light-tertiary-bg);
    color: black;
}

.picker-date-month-option {
    padding: 2px;
    height: 70px;
    width: 70px;
    margin-bottom: 3px;
    border-radius: 50px;
    border: none;
    cursor: pointer;
}

.picker-date-month-option-active {
    background-color: #5090d4cb;
    color: white;
    font-weight: bold;
}

.picker-date-month-option-inactive {
    background-color: transparent;
}

.picker-date-month-option-dark {
    color: white;
}

.picker-date-month-option-light {
    color: black;
}

.picker-date-month-option-dark:hover {
    background-color: #4d4d4d;  
    color: white;
}

.picker-date-month-option-light:hover {
    background-color: var(--light-tertiary-bg);
    color: black;
}