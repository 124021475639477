.settings-desp-card-container {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 10px;
    margin-left: 30px;
    margin-right: 25px;
    margin-bottom: 40px;
}

.settings-desp-card-container-div {
    display: flex;
    align-items: center;
}

.settings-desp-card-img {
    width: 20px;
    height: 20px;
    padding: 10px;
}

.admin-view-card-container {
    text-decoration: none;
    width: 100%;
    display: flex;
    margin: 20px 0 20px 0;
    border-radius: 100px;
    border: 1px solid transparent;
}

.admin-view-card-container-dar {
    color: white;
}

.admin-view-card-container-lig {
    color: black;
}

.admin-view-card-img-div {
    width: 50px;
    height: 50px;
    padding: 0.5px;
}

.admin-view-card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
}

.admin-view-card-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    margin-left: 19px;

    p {
        white-space: nowrap;
    }
}