.settings-container-header3 {
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
}

.settings-container-header3-img {
    width: 20px;
    margin-right: 15px;
}

.settings-text-link-header3 {
    text-decoration: none;
    // opacity: 0.6;
}

.settings-text-link-header-dark-text {
    color: rgba(255, 255, 255, 0.550);
}

.settings-text-link-header-light-text {
    color: rgba(0, 0, 0, 0.550);
}

.settings-text-link-header-dark-text:hover {
    color: white;
}

.settings-text-link-header-light-text:hover {
    color: black;
}