.outside-button-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin: 10px;
    margin-top: 20px;
    text-decoration: none;

    color: white;
    background-color: var(--dark-primary);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    
    .outside-button-container-img {
        height: 20px;
        width: 20px;
    }

    .outside-button-container-text {
        margin-left: 15px;
        font-size: 16px;
    }
}

.outside-button-container:hover {
    background-color: #3f5266;
}