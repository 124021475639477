.agree-payment-method-a {
    margin-top: 50px;
    text-decoration: none;
    background-color: #25d365c2;
    color: white;
    padding: 10px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
}

.agree-payment-method-a:hover {
    background-color: #0f9640a8;
}

.agree-payment-method-img {
    width: 20px;
    height: 20px;
    margin: 5px;
}