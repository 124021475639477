.page-name-p {
    margin-left: 10px;
    font-weight: bold;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Número de líneas que deseas mostrar */
    text-align: start;
    line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    height: calc(1.5em * 1); /* Ajusta la altura según el número de líneas */
    line-height: 1.5em; /* Ajusta según la altura de la línea de tu fuente */
}