.app-unlock-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    overflow: hidden;  
    overflow-y: scroll;
}

.app-unlock-container::-webkit-scrollbar {
    width: 0.4em;
}

.app-unlock-container::-webkit-scrollbar-thumb {
    background: #9ba3b81f;
    border-radius: 100vw;
}

.app-unlock-card {
    width: 100%;
    margin-top: 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app-unlock-card-title-card {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        padding: 20px;
    }
    
    h4 {
        margin-top: 20px;
    }
}

.app-unlock-card-title-card-div {
    display: flex;
}

.app-unlock-dif-prof-butt-div {
    width: 280px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 40px;
    padding: 0 20px 0 20px;
}

.app-unlock-dif-prof-butt {
    padding: 8px;
    border-radius: 100px;
    border: none;
    margin-top: 10px;
    font-size: 12px;
    cursor: pointer;

    color: white;
    background-color: var(--dark-primary);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
}

.app-unlock-dif-prof-butt:hover {
    background-color: #3f5266;
}

.app-unlock-card-button {
    display: flex;
    width: 280px;
    border: none;
    height: 40px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 40px;

    color: white;
    background-color: var(--dark-primary);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);

    img {
        width: 18px;
        padding: 5px;
        margin-right: 10px;
    }

    p {
        padding: 8px;
        margin-left: 10px;
    }
}

.app-unlock-card-button:hover {
    background-color: #3f5266;
}

.app-unlock-spinner-div {
    margin-top: 60px;
}

.app-unlock-login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 40px;

    input {
        margin: 2px 10px 10px 10px;
        padding: 8px;
        margin-top: 10px;
        border-radius: 100px;
        font-size: 16px;
        width: 260px;
    }

    .app-unlock-login-form-button {
        display: flex;
        width: 280px;
        border: none;
        height: 40px;
        border-radius: 100px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 20px;

        color: white;
        background-color: var(--dark-primary);
        box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);

        img {
            width: 18px;
            padding: 5px;
            margin-right: 10px;
        }

        p {
            padding: 8px;
            margin-left: 10px;
        }
    }

    .app-unlock-login-form-button:hover {
        background-color: #3f5266;
    }

    a {
        margin-top: 30px;
    }
}

.app-unlock-input-content-normal {
    border: solid 3px transparent;
    box-shadow: 0px 0px 0px 1.5px rgba(0, 0, 0, 0.2);
}

.app-unlock-input-content-alert {
    border: solid 3px var(--alert-red);
    box-shadow: none;
}