.settings-protect-access-cont {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 40px 25px 20px 30px;

}

.settings-protect-access-title-con {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .settings-protect-fingerprint {
        width: 28px;
        margin: 10px;
    }

    .settings-protect-status {
        width: 12px;
        position: relative;
    }

    p {
        margin: 5px;

    }
}

.settings-protect-access-title-descp {
    margin-top: 50px;
    margin-bottom: 30px;
    justify-content: center;
    display: flex;
}

.settings-protect-access-title-button {

    display: flex;
    width: 100%;
    justify-content: center;

    button {
        display: flex;
        width: 200px;
        border: none;
        height: 45px;
        border-radius: 100px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 40px;

        color: white;
        background-color: var(--dark-primary);
        box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);

        p {
            padding: 8px;
        }
    }

    button:hover {
        background-color: #3f5266;
    }
}