.settings-options-container-item-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.authorize-access-page-description {
    margin-top: 25px;
    max-width: 300px;
}

.authorize-access-descripcion {
    font-size: 14px;
    text-decoration: underline;
    color: rgb(0, 185, 185);
    margin-left: 15px;
    cursor: pointer;
}

.authorize-access-page-container {
    width: 400px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.authorize-access-page-alert-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    text-align: center;

    p {
        padding: 10px 20px 10px 20px;
    }

    img {
        width: 65px;
        margin: 20px;
    }
}

.authorize-access-page-form {
    width: 100%;
    max-width: 380px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.authorize-access-page-div {
    display: flex;
    flex-direction: column;
    margin: 20px;
    border-radius: 10px;
    padding: 10px;
}

.authorize-access-input-description {
    margin: 10px;
}

.authorize-access-input-content {
    margin: 2px 10px 10px 10px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
}

.authorize-access-button-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin: 10px;
    margin-top: 20px;
    text-decoration: none;

    color: white;
    background-color: var(--dark-primary);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    
    .authorize-access-button-container-img {
        height: 20px;
        width: 20px;
    }

    .authorize-access-button-container-text {
        margin-left: 15px;
    }
}

.authorize-access-input-content-alert {
    border: solid 3px var(--alert-red);
}

.authorize-access-button-container:hover {
    background-color: #3f5266;
}