.product-details-page-container {
    width: calc(100% - 40px);
    margin: 0px 20px 20px 20px;
    max-width: 1000px;
}

.product-details-page-botttons-section-main-cont {
    display: flex;
    justify-content: center;
    width: 100%;
}

.product-details-page-botttons-section {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    width: calc(100% - 20px);
    max-width: 1000px;
    margin: 10px 0px 0px 20px;
}

.product-details-page-info-section {
    display: flex;
    padding-bottom: 3px;
    margin-top: 10px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.315);
}

.products-inventory-product-yes-img-big-cont {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

.pro-inv-prod-yes-img-big-con-div:hover {
    img {
        opacity: 0.8;
    }
}

.products-inventory-product-yes-img-big {
    border-radius: 0px;
    background-color: rgba(128, 128, 128, 0.171);
    border-radius: 10px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.products-inventory-product-no-img-big-cont {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

.pro-inv-prod-no-img-big-con-div {
    background-color: rgba(128, 128, 128, 0.171);
    border-radius: 10px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.products-inventory-product-no-img-big {
    width: 70px;
    height: 70px;
    border-radius: 0px;
}

.product-details-page-inventory-section {
    display: flex;
    margin-top: 10px;
    padding-bottom: 3px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.315);
}

.product-details-page-billing-section {
    display: flex;
    margin-top: 20px;
    padding-bottom: 3px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.315);
}

.labelDoNotDefQuaContainer {
    padding: 5px;
    cursor: pointer;
    border-radius: 10px;
    width: auto;
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 10px;
    max-width: 340px;

    p {
        display: flex;
        align-items: center;
        white-space: nowrap;

        label {
            padding: 4px 10px 4px 15px;
        }
    }
}

.labelDoNotDefQuaContainer:hover {
    background-color: rgba(173, 173, 173, 0.288);
}

.labelDoNotDefQuaContainer-no-hover {
    padding: 5px;
    cursor: pointer;
    border-radius: 10px;
    width: auto;
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 10px;
    max-width: 340px;

    p {
        display: flex;
        align-items: center;
        white-space: nowrap;

        label {
            padding: 4px 10px 4px 15px;
        }
    }
}

.labelDoNotDefQuaContainer-no-hover:active {
    background-color: rgba(173, 173, 173, 0.288);
}