.more-notes-spinner {
    margin: 10px auto 0;
    width: 100px;
    text-align: center;
}
  
.more-notes-spinner > div {
    width: 12px;
    height: 12px;
    margin: 5px;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
  
.more-notes-spinner .more-notes-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
  
.more-notes-spinner .more-notes-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
  
@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}
  
@keyframes sk-bouncedelay {
    0%, 80%, 100% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% { 
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}