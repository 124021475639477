.cash-in-cash-reg-input-alert {
    border: solid 3px rgba(255, 0, 0, 0.486);
}

.cash-in-cash-reg-input {
    outline: none;
    width: calc(100% - 20px);
    border-radius:  10px;
    margin-top: 5px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    color: black;
    padding: 10px;
    font-size: 17px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cash-in-cash-reg-input::placeholder {
    color: rgba(177, 177, 177, 0.87);
    font-weight: 400; 
    opacity: 1; /* Firefox */
}
  
.cash-in-cash-reg-input::-ms-input-placeholder { /* Edge 12 -18 */
    color: rgba(177, 177, 177, 0.87);
    font-weight: 400; 
}

.cash-in-cash-reg-input-dark {
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.cash-in-cash-reg-input-light {
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.cash-in-cash-reg-input-dark:focus {
    border: 1px solid rgb(108, 167, 221);
}

.cash-in-cash-reg-input-light:focus {
    border: 1px solid rgba(108, 166, 221, 0.884);
}

.cash-in-cash-reg-bottons-button-save {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--dark-primary);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }

}

.cash-in-cash-reg-bottons-button-save:hover {
    background-color: #3f5266;
}