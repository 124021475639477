.account-details-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.account-details-container-minimize {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.account-details-container-minimize-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.account-details-name {
    font-size: 18px;
}

.account-details-status-active {
    font-weight: bold;
    padding: 5px 10px 5px 10px;
    border-radius: 15px;
    background-color: rgba(47, 255, 75, 0.24);
    margin-left: 5px;
    font-size: 12px;
}

.account-details-status-inactive {
    font-weight: bold;
    padding: 5px 10px 5px 10px;
    border-radius: 15px;
    background-color: rgba(252, 255, 47, 0.377);
    margin-left: 5px;
    font-size: 12px;
}

.account-details-acc-num {
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 16px;
    border-radius: 10px;

    img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
    }
}

.account-details-acc-num-minimize {
    font-weight: bold;
    cursor: pointer;
    display: flex;
    
    align-items: center;
    justify-content: flex-end;
    padding: 5px;
    font-size: 16px;
    border-radius: 10px;

    img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
    }
}

.account-details-acc-num:hover {
    background-color: rgba(128, 128, 128, 0.158);
}

.account-details-acc-num-minimize:hover {
    background-color: rgba(128, 128, 128, 0.158);
}

