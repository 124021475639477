.settiongs-title-bus-option {
    display: flex;
    padding: 20px 0 30px 10px;
    margin-left: 20px;
    
    img {
        width: 50px;
        height: 50px;
        margin-right: 20px;
    }
}

.settiongs-title-bus-option-texts {
    line-height: 24px;
}