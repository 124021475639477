.page-my-notifications-container {
    width: 100%;
    height: calc(100vh - 65px);
    flex-direction: column;
    display: flex;
    overflow: hidden;
}

.page-my-notifications-item {
    width: 100%;
    height: 100%;
}

.page-my-notifications-options-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-my-notifications-options-container-item {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.notification-list-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.nofificaton-list-first-section-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nofificaton-list-first-section {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    max-width: 700px;
    padding-right: 20px;
}

.notification-list-config-btn {
    padding: 8px 15px;
    border-radius: 20px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
    }

    input {
        min-width: 18px;
        min-height: 18px;
        cursor: pointer;
        margin-right: 10px;
    }
}

// HOVER
.settings-notification-btn-dark {
    background-color: var(--dark-secundary-bg);
    color: white;
}

.settings-notification-btn-light {
    background-color: var(--light-secundary-bg);
    color: black;
}

.settings-notification-btn-dark:hover {
    background-color: var(--dark-tertiary-bg);
}

.settings-notification-btn-light:hover {
    background-color: var(--light-tertiary-bg);
}

// NO HOVER
.settings-notification-btn-dark-no-hover {
    background-color: var(--dark-secundary-bg);
    color: white;
}

.settings-notification-btn-light-no-hover {
    background-color: var(--light-secundary-bg);
    color: black;
}

.settings-notification-btn-dark-no-hover:active {
    background-color: var(--dark-tertiary-bg);
}

.settings-notification-btn-light-no-hover:active {
    background-color: var(--light-tertiary-bg);
}

.settings-notification-btn-dark-no-hover:visited {
    background-color: var(--dark-tertiary-bg);
}

.settings-notification-btn-light-no-hover:visited {
    background-color: var(--light-tertiary-bg);
}

.notification-pointing-up {
    content: '';
    position: absolute;
    margin-top: 8px;
    margin-left: 50px;
    height: 15px;
    width: 15px;
    z-index: 0;
}

.notification-pointing-up.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transform: rotate(45deg);
    transition: 200ms ease;
}

.notification-pointing-up.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 200ms ease;
}

.dropdown-menu-notification {
    position: fixed;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-left: -213px;
    background-color: var(--light-secundary-bg);
    border-radius: 8px;
    padding: 10px;
    width: 300px;
    max-height: calc(100vh - 165px);
    overflow: hidden;  
    overflow-y: auto;
    min-height: 100px;
}

.dropdown-menu-notification::-webkit-scrollbar {
    width: 0.4em;
}

.dropdown-menu-notification::-webkit-scrollbar-thumb {
    background: #9ba3b81f;
    border-radius: 100vw;
}

.dropdown-menu-notification.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 200ms ease;
}

.dropdown-menu-notification.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 200ms ease;
}

.dropdown-menu-notification-dark {
    background-color: var(--dark-secundary-bg);
    color: white;
}

.dropdown-menu-notification-dark::before {
    background-color: var(--dark-secundary-bg);
}

.drop-men-not-option-con {
    h3 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
    }
}

.drop-men-not-save-con {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

// HOVER
.drop-men-not-option-btn {
    display: flex;
    align-items: center;
    margin-top: 10px;
    background-color: transparent;
    border: none;
    padding: 0px 5px;
    cursor: pointer;
    border-radius: 20px;
    width: 100%;

    p {
        font-size: 15px;
    }

    img {
        width: 38px;
        height: 38px;
        margin-right: 10px;
    }
}

.drop-men-not-option-btn:hover {
    background-color: rgba(128, 128, 128, 0.199);
}

// NO HOVER
.drop-men-not-option-btn-no-hover {
    display: flex;
    align-items: center;
    margin-top: 10px;
    background-color: transparent;
    border: none;
    padding: 0px 5px;
    cursor: pointer;
    border-radius: 20px;
    width: 100%;

    p {
        font-size: 15px;
    }

    img {
        width: 38px;
        height: 38px;
        margin-right: 10px;
    }
}

.drop-men-not-option-btn-no-hover:active {
    background-color: rgba(128, 128, 128, 0.199);
}

.no-notification-to-profile-msg-div {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 50px;

    img {
        width: 65px;
        height: 65px;
        padding: 20px;
    }

    p {
        font-size: 16px;
        color: gray;
        padding: 10px;
        text-align: center;
    }
}