.page-app-home-container {
    width: 100%;
    flex-direction: column;
    display: flex;
    overflow: hidden;  
    overflow-y: auto;
}

.page-app-home-item {
    width: 100%;
    height: 100%;
}

.home-options-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.home-options-container-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin-bottom: 50px;
}

.welcome-to-business-account-cont {
    width: 100%;
    display: flex;
    justify-content: center;
}

.welcome-to-business-account-div {
    display: flex;
    align-items: center;
    width: 1000px;
    padding: 30px 30px 5px 30px;

    img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .welcome-to-business-account-title {
        font-size: 16px;
    }
}

.page-choose-tools-options-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.page-choose-tools-options {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
}

.page-choose-tools-option {
    height: 160px;
    min-height: 160px;
    margin: 15px;
    cursor: pointer;
    border-radius: 10px;
    width: 135px;
    min-width: 135px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
}

.page-choose-tools-option-dar {
    background-color: var(--dark-secundary-bg);
    border: 1px solid rgba(255, 255, 255, 0.075);
    border-right: none;
}

.page-choose-tools-option-lig {
    background-color: var(--light-secundary-bg);
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-right: none;
}

.page-choose-tools-option-dar:hover {
    background-color: var(--dark-tertiary-bg);
}

.page-choose-tools-option-lig:hover {
    background-color: var(--light-tertiary-bg);
}

.page-choose-tools-link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    height: 100%;

    img {
        width: 50px;
        height: 50px;
        margin: 10px;
    }

    p {
        padding: 12px;
        text-align: center;
    }
}