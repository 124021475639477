.sales-mini-view-texto-overflow {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Número de líneas que deseas mostrar */
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    height: calc(1.4em * 2); /* Ajusta la altura según el número de líneas */
    line-height: 1.5em; /* Ajusta según la altura de la línea de tu fuente */
}

.products-mini-view-product-ref {
    padding-left: 5px;
}

.products-mini-view-product-price {
    padding: 5px 5px 0 5px;
    font-weight: bold;
    white-space: nowrap;
}