.internet-error-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 70px;
}

.internet-error-button {
    padding: 10px 20px 10px 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    color: white;
    background: var(--dark-primary);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;

    img {
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }
}

.internet-error-button:hover {
    background-color: #3f5266;
}

.internet-error-header {
    display: flex;
    align-items: center;

    img {
        margin-right: 20px;
        width: 50px;
        height: 50px;
    }
}