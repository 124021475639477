.side-menu-button-container {
    border: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 95%;
    border-radius: 10px;
    text-decoration: none;
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);

    .side-menu-icon {
        width: 30px;
        height: 30px;
        margin: 10px;
    }

    .side-menu-description {
        margin: 10px;
    }
}

.side-menu-icon-down-arrow {
    width: 14px;
    height: 14px;
    margin-right: 15px;
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
}

.side-menu-icon-down-arrow.is-open {
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;
}

.side-menu-button-light {
    border-left-style: solid;
    border-left-width: 0.3em;
    border-left-color: transparent;

    border-right-style: solid;
    border-right-width: 0.3em;
    border-right-color: transparent;

    background: transparent;
    color: black;
}

.side-menu-button-dark {
    border-left-style: solid;
    border-left-width: 0.3em;
    border-left-color: transparent;

    border-right-style: solid;
    border-right-width: 0.3em;
    border-right-color: transparent;

    background: transparent;
    color: white;
}

.side-menu-button-light:hover {
    background-color: var(--light-tertiary-bg);
}

.side-menu-button-dark:hover {
    background-color: var(--dark-tertiary-bg);
}

.side-menu-button-light-focus {
    border-left-style: solid;
    border-left-width: 0.3em;
    border-left-color: var(--focus-secundary-bg);

    border-right-style: solid;
    border-right-width: 0.3em;
    border-right-color: var(--focus-secundary-bg);

    color: black;
    background-color: var(--light-tertiary-bg);
}

.side-menu-button-dark-focus {
    border-left-style: solid;
    border-left-width: 0.3em;
    border-left-color: var(--focus-secundary-bg);

    border-right-style: solid;
    border-right-width: 0.3em;
    border-right-color: var(--focus-secundary-bg);

    color: white;
    background-color: var(--dark-tertiary-bg);
}