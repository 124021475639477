.settings-take-payment {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    margin: 10px;
    margin-left: 30px;
    margin-right: 25px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;

    p {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 17px;
    }
}

.settings-take-payment-name-numbre-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 330px;
    margin-top: 10px;

    label {
        margin: 20px 0 10px 0;
        font-size: 12px;
    }

    input {
        padding: 10px;
        border-radius: 5px;
        font-size: 15px;
    }
}

.settings-take-payment-date-code-cont {
    display: flex;
    width: 100%;
    max-width: 330px;
    flex-wrap: wrap;
    justify-content: space-between;

    div {
     
        display: flex;
        flex-direction: column;

        label {
            margin: 20px 0 10px 0;
            font-size: 12px;
        }

        input {
            padding: 10px;
            border-radius: 5px;
            font-size: 15px;
            width: 120px;
        }
    }
}

.input-border-card {
    border: solid 3px transparent;
    box-shadow: 0px 0px 0px 1.5px rgba(0, 0, 0, 0.2);
}

.settings-take-payment-pay {
    width: 100%;
    max-width: 330px;
    border-radius: 100px;
    border: none;
    padding: 13px;
    margin-top: 50px;
    font-size: 14px;
    cursor: pointer;
}