
.agree-terms-and-cond-cont {
    padding: 10px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
    align-items: center;
}

.agree-terms-and-cond-cont-checkbox {
    min-width: 20px;
    min-height: 20px;
    padding: 10px;
    cursor: pointer;
}

.agree-terms-and-cond-cont-div {
    p {
        margin-left: 10px;
    }
}

.agree-terms-and-cond-cont-link {
    color: #00b9b9;
}

.agree-terms-and-cond-cont-link:hover {
    color: #d44f4f;
}

