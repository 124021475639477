.tool-title-header {
    padding: 20px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
}

.tool-title-container-header-img {
    width: 15px;
    height: 15px;
    margin-right: 8px;
}