.customer-detaines-info-cont {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    max-width: 500px;
    height: auto;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
}

.customer-more-details-section-container {  
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
}

.customer-more-details-section-title-cont {
    display: flex;
    align-items: center;
    justify-content: center;
}

.customer-detaines-info-p {
    margin-top: 13px;
    font-size: 16px;
    line-height: 1.5;

    .customer-detaines-info-p-span-title {
        font-weight: 700;
        margin-right: 10px;
    }

    .customer-detaines-info-p-span-cont {
        cursor: pointer;
        padding: 3px 8px;
        margin-left: -8px;
        border-radius: 15px;
    }

    .customer-detaines-info-p-span-cont:hover {
        background-color: rgba(128, 128, 128, 0.13);
    }
}

.customer-detaines-main-header-id-div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: -15px;
}

.customer-detaines-main-header-id-img {
    width: 18px;
    height: 18px;
}

.customer-detaines-main-header-id-p {
    font-size: 18px;
    font-weight: bold;
    padding: 3px 8px;
    border-radius: 15px;
}

.customer-detaines-main-header-name-div {
    display: flex;
    align-items: center;
}

.customer-detaines-main-status-icons {
    width: 16px;
    height: 16px;
    margin-right: 3px;
}

