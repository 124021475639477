.settings-payment-history {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 10px;
    margin-left: 30px;
    margin-right: 25px;
    margin-bottom: 20px;
}

.settings-payment-history-empty-con {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    img {
        width: 70px;
        margin: 20px;
        margin-top: 30px;
    }
}

.settings-payment-history-item-hr {
    margin: 10px 0px 20px 0px;
}

.settings-payment-history-item-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    p {
        font-size: 14px;
    }
}

.settings-payment-history-status-icon-con {
    min-width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.settings-payment-history-status-icon {
    width: 18px;
    height: 18px;
    margin: 5px;
}