.customer-sales-rec-main-container {
    width: 100%;
    // height: 100%;
    max-height: calc(100vh - 280px);;
    display: flex;
    flex-direction: column;
}

.no-sales-rec-yet-main-container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;

    p {
        text-align: center;
        padding: 10px;
        color: gray;
    }
}

.no-sales-rec-yet-main-img {
    width: 80px;
    height: 80px;
    margin: 20px;
}

.cust-account-sales-rec-records {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;  
    overflow-y: scroll;
}

.cust-account-sales-rec-records::-webkit-scrollbar {
    width: 0.7em;
}

.cust-account-sales-rec-records::-webkit-scrollbar-thumb {
    background: #9ba3b85d;
    border-radius: 100vw;
}

.cust-sales-rec-all-cards-main-cont {
    padding-bottom: 20px;
}