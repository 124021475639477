.nav-container-quick-search-button { 
    margin: 10px;
    padding: 3px;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    
    .nav-container-quick-search-button-item {
        padding: 8px;
        width: 18px;
        height: 18px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-navigation {
            border-radius: 5px;
        }
    }
}
