.soft-alert-cont {
    position: absolute;
    top:  90px;
    right: 30px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    min-width: 280px;
    max-height: calc(100vh - 100px);
    overflow: hidden;  
    overflow-y: scroll;
    z-index: 5;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.soft-alert-cont::-webkit-scrollbar {
    width: 0.4em;
}

.soft-alert-cont::-webkit-scrollbar-thumb {
    background: #9ba3b81f;
    border-radius: 100vw;
}

.soft-alert-cont-dark {
    background-color: var(--dark-secundary-bg);
    color: white;
}

.soft-alert-p {
    font-size: 14px;
    font-weight: 500;
    padding: 4px 0 4px 20px;
    color: white;
}

.soft-alert-button {
    background-color: transparent;
    border: none;
    padding: 6px 10px 5px 10px;
    cursor: pointer;
    
}

.soft-alert-img {
    width: 14px;
    height: 14px;
}

.soft-alert-cont.active {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
    transition: 200ms ease;
}

.soft-alert-cont.inactive {
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0.5);
    transition: 200ms ease;
}

.soft-alert-background-orange {
    background-color: #ffcc00;
    color: white;
}

.soft-alert-background-green {
    background-color: #25d365;
    color: white;
}