.lockScreenContainer {
    top: -1px;
    width: 100%;
    min-width: 100vh;
    min-height: 100vh;
    background:rgba(0,0,0,0.3);
    position: absolute;
    z-index: 4;
}

.navigation-mobile-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    z-index: 1;
    border-top-style: solid;
    border-top-width: 0.14em;
    border-top-color: rgba(128, 128, 128, 0.137);

    position: fixed !important;
    bottom: -60px !important;
    height: 70px !important;
    left: 0px !important;
    right: 0px !important;
    padding-bottom: 60px !important;
    
}

.navigation-mobile-list {
    display: flex;
    width: 100%;
    height: 50px;
    margin: 10px;
    justify-content: center;
}

.navigation-mobile-container-link {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80px;
    text-align: center;
    text-decoration: none;
    border-radius: 100px;
}

.mobile-main-options-link-light {
    color: black;
}

.mobile-main-options-link-dark {
    color: white;
}

.mobile-main-options-link-light-focus {
    color: black;
    span {
        font-weight: bolder;
    }
}

.mobile-main-options-link-dark-focus {
    color: white;
    span {
        font-weight: bolder;
    }
}

.mobile-main-options-link-light:active {
    background-color: var(--light-tertiary-bg);
}

.mobile-main-options-link-dark:active {
    background-color: var(--dark-tertiary-bg);
}

.navigation-mobile-icon-img {
    width: 20px;
    height: 20px;
}

.navigation-mobile-text {
    margin-top: 5px;
    font-size: 12px;
}

.nav-container-cart-btn-button {
    margin: 10px;
    padding: 3px;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;

    .nav-container-cart-btn-button-item {
        padding: 8px;
        width: 18px;
        height: 18px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-navigation {
            border-radius: 5px;
        }
    }
}

.cart-counter-btn-nav {
    position: fixed;
    background-color: rgb(201, 61, 61);
    min-width: 15px;
    height: 22px;
    margin-left: 25px;
    padding: 0.5px 3.5px;
    top: 10px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-weight: bold;
        color: white;
        font-size: 14px;
    }
}

.dropDownCart-menu {
    position: absolute;
    top: 64px;
    right: 15px;
    background-color: var(--light-secundary-bg);
    border-radius: 8px;
    padding: 10px 0 0 10px;
    width: 333px;
    max-height: calc(100vh - 100px);
    overflow: hidden;
    overflow-y: scroll;
}

.dropDownCart-menu::-webkit-scrollbar {
    width: 0.4em;
}

.dropDownCart-menu::-webkit-scrollbar-thumb {
    background: #9ba3b81f;
    border-radius: 100vw;
}

.dropDownCart-menu-dark {
    background-color: var(--dark-secundary-bg);
    color: white;
}

// pointing up
.dropDownCart-pointingUp {
    content: '';
    position: absolute;
    top: 60px;
    height: 20px;
    width: 20px;
    background: var(--light-secundary-bg);
    z-index: 0;
}

.dropDownCart-pointingUp.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transform: rotate(45deg);
    transition: 200ms ease;
}

.dropDownCart-pointingUp.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 200ms ease;
}

.dropDownCart-menu-dark::before {
    background-color: var(--dark-secundary-bg);
}

.dropDownCart-menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 200ms ease;
}

.dropDownCart-menu.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 200ms ease;
}

.nav-container-cart-title {
    display: flex;
    padding-top: 5px;
    padding-bottom: 10px;

    img {
        width: 25px;
        height: 25px;
        margin-right: 18px;
    }
}

.nav-container-cart-items {
    height: 235px;
    margin-bottom: 10px;
}

.cart-counter-btn-menu {
    position: fixed;
    background-color: rgb(201, 61, 61);
    min-width: 15px;
    height: 22px;
    margin-left: 18px;
    padding: 0 3px;
    top: 5px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-weight: bold;
        color: white;
        font-size: 14px;
    }
}

.shopping-cart-btn-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
        padding: 10px 20px;
        border-radius: 20px;
        box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        border: none;
        color: white;
        background-color: var(--dark-primary);
        margin-top: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        img {
            width: 16px;
            height: 16px;
            margin-left: 10px;
        }
    }

    button:hover {
        background-color: #3f5266;
    }
}

.cart-btn-item-comp {
    width: calc(100% - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 5px 5px 5px;
    padding-top: 15px;
}

.cart-btn-item-comp-section {
    display: flex;
    align-items: center;
}

.cart-btn-item-comp-section-div-price-and-name {
    padding-left: 12px;

    p {
        font-size: 13px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.cart-btn-input-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    border: none;
    cursor: pointer;

    .quatity-btn {
        width: 12px;
        height: 12px;
    }

    .delete-btn {
        width: 18px;
        height: 18px;
    }
}

// HOVER *****************************************
.cart-btn-input-item-dark {
    background-color: var(--dark-secundary-bg);
    color: white;
}

.cart-btn-input-item-light {
    background-color: var(--light-secundary-bg);
    color: black;
}

.cart-btn-input-item-dark:hover {
    background-color: #616161c2;
    color: white;
}

.cart-btn-input-item-light:hover  {
    background-color: #d8d8d8;
    color: black;
}

// NO HOVER **************************************
.cart-btn-input-item-dark-no-hover {
    background-color: var(--dark-secundary-bg);
    color: white;
}

.cart-btn-input-item-light-no-hover {
    background-color: var(--light-secundary-bg);
    color: black;
}

.cart-btn-input-item-dark-no-hover:active {
    background-color: #616161c2;
    color: white;
}

.cart-btn-input-item-light-no-hover:active  {
    background-color: #d8d8d8;
    color: black;
}

.cart-btn-input-item-dark-no-hover:visited {
    background-color: #616161c2;
    color: white;
}

.cart-btn-input-item-light-no-hover:visited  {
    background-color: #d8d8d8;
    color: black;
}
// *****************************************************

.cart-quan-input-item {
    width: 60px;
    height: 35px;
    margin: 0 10px;
    border-radius: 10px;
    text-align: center;
}

.products-individual-mini-product-no-img-cont {
    display: flex;
    width: 75px;
    height: 75px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: rgba(128, 128, 128, 0.171);
}

.products-individual-mini-product-yes-img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.products-individual-mini-product-no-img {
    width: 30px;
    height: 30px;
}

.nav-container-cart-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 162px;

    img {
        width: 65px;
        height: 65px;
    }

    p {
        font-size: 14px;
        margin-top: 10px;
        color: gray;
    }
}

.nav-container-user-menu-button { 
    margin: 10px 10px 10px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px;
    border: none;
    border-radius: 20px;
    cursor: pointer;

    .nav-container-notification-button-p {
        width: 100%;
        display: flex;
        overflow: hidden;

        p {
            text-align: center; 
            white-space: nowrap;
            padding-left: 5px;
        }
    }

    .nav-container-notification-button-item {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;

        .icon-navigation {
            border-radius: 100px;
            padding: 10px;
        }

        .icon-navigation-div-cont {
            width: 34px;
            height: 34px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 100px;
            }
        }
    }
}

.dropdown-menu {
    position: absolute;
    top:  64px;
    right: 15px;
    background-color: var(--light-secundary-bg);
    border-radius: 8px;
    padding: 10px 10px 0 10px;
    width: 190px;
    height: 310px;
    max-height: calc(100vh - 100px);
    overflow: hidden;  
    overflow-y: scroll;

    h3 {
        width: 100%;
        text-align: center;
        font-size: 16px;
        padding: 10px 0;
        font-weight: 500;
        color: #242526;
        line-height: 1.2rem;
    }

    .business-name-icon-text {
        font-size: 14px;
        color: #555;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;

        img {
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }
    }

    ul {
        list-style: none;
    }
}

.dropdown-menu::-webkit-scrollbar {
    width: 0.4em;
}

.dropdown-menu::-webkit-scrollbar-thumb {
    background: #9ba3b81f;
    border-radius: 100vw;
}

.dropdown-menu-dark {
    background-color: var(--dark-secundary-bg);
    color: white;

    h3 {
        color: white;

        span {
            color: white;
            opacity: 0.8;
        }
    }
}

// pointing up
.pointingUp {
    content: '';
    position: absolute;
    top: 60px;
    height: 20px;
    width: 20px;
    background: var(--light-secundary-bg);
    z-index: 0;
}

.pointingUp.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transform: rotate(45deg);
    transition: 200ms ease;
}

.pointingUp.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 200ms ease;
}

.dropdown-menu-dark::before {
    background-color: var(--dark-secundary-bg);
}

.dropdown-menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 200ms ease;
}

.dropdown-menu.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 200ms ease;
}

.dropdown-menu ul li {
    display: flex;
    width: 100%;
}

.dropdown-menu-dark ul li a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding:  10px 0;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
}

.dropdown-menu-dark ul li a:hover {
    cursor: pointer;
    background-color: var(--dark-tertiary-bg);
}

.dropdown-menu-light ul li a {
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding:  10px 0;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
}

.dropdown-menu-light ul li a:hover {
    cursor: pointer;
    background-color: var(--light-tertiary-bg);
}

.dropdown-item {
    display: flex;
    margin: 10px auto;
}

.dropdown-menu-picture-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.user-picture-normal-div {
    width: 70px;
    height: 70px;
}

.user-picture-normal {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    object-fit: cover;
    pointer-events: none;
}

.dropdown-item img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    opacity: 0.8;
    transition: 200ms;
}

.dropdown-item p {
    max-width: 100px;
    margin-left: 10px;
    transition: 500ms;
}

// HOVER ***********************************************
.nav-container-user-menu-button-light {
    background-color: var(--light-secundary-bg);
    color: black;
}

.nav-container-user-menu-button-dark {
    background-color: var(--dark-secundary-bg);
    color: white;
}

.nav-container-user-menu-button-light:hover {
    background-color: var(--light-tertiary-bg);
}

.nav-container-user-menu-button-dark:hover {
    background-color: var(--dark-tertiary-bg);
}

// NO HOVER ********************************************
.nav-container-user-menu-button-light-no-hover {
    background-color: var(--light-secundary-bg);
    color: black;
}

.nav-container-user-menu-button-dark-no-hover {
    background-color: var(--dark-secundary-bg);
    color: white;
}

.nav-container-user-menu-button-light-no-hover:active {
    background-color: var(--light-tertiary-bg);
}

.nav-container-user-menu-button-dark-no-hover:active {
    background-color: var(--dark-tertiary-bg);
}

.nav-container-user-menu-button-light-no-hover:visited {
    background-color: var(--light-tertiary-bg);
}

.nav-container-user-menu-button-dark-no-hover:visited {
    background-color: var(--dark-tertiary-bg);
}
// *************************************************************