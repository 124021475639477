.products-services-first-section {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.products-services-first-item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1000px;
    justify-content: space-around;
}

.products-services-second-section {
    padding: 15px;
    display: flex;
    width: calc(100% - 30px);
    justify-content: center;
}

// ************************** Scroll Element
.products-cards-main-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
}

.products-cards-items {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 15px;
}

.input-type-filter-price-num-text {
    width: calc(100% - 12px);
    padding: 10px 2px 10px 10px;
    border: none;
    border-radius: 10px;
    margin-top: 5px;
}

.input-type-filter-price-num-text[type="text"] {
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.products-space-between-page-nav {
    flex-grow: 4;
}

.view-products-pointing-up {
    content: '';
    position: absolute;
    margin-top: 45px;
    margin-left: 42px;
    height: 15px;
    width: 15px;
    background: var(--light-secundary-bg);
    z-index: 0;
}

.view-products-pointing-up.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transform: rotate(45deg);
    transition: 200ms ease;
}

.view-products-pointing-up.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 200ms ease;
}

.dropdown-view-products {
    position: fixed;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 10px;
    background-color: var(--light-secundary-bg);
    border-radius: 8px;
    padding: 5px 10px 0 10px;
    width: 190px;
    max-height: calc(100vh - 245px);
    overflow: hidden;  
    overflow-y: scroll;
    min-height: 100px;
}

.dropdown-view-products::-webkit-scrollbar {
    width: 0.4em;
}

.dropdown-view-products::-webkit-scrollbar-thumb {
    background: #9ba3b81f;
    border-radius: 100vw;
}

.dropdown-view-products.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 200ms ease;
}

.dropdown-view-products.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 200ms ease;
}

.view-products-options-cont {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
}

.view-products-options-btn {
    margin: 5px 0px 5px 8px;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 10px;

    img {
        width: 20px;
        height: 20px;
        padding: 5px;
    }

    p {
        margin-left: 5px;
        margin-top: -3px;
    }

    input {
        width: 18px;
        height: 18px;
        margin-right: 5px;
        cursor: pointer;
    }
}

.view-products-options-btn-light:hover {
    background-color: var(--light-tertiary-bg);
}

.view-products-options-btn-dark:hover {
    background-color: var(--dark-tertiary-bg);
}