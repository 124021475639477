.picture-product-viewer-lock-screen {
    width: calc(100%);
    position: absolute;
    z-index: 2;
}

.picture-product-viewer-card {
    color: black;
    width: calc(100% - 11px);
    z-index: 3;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 65px);
    min-height: calc(100vh - 65px);
    overflow: hidden;  
    overflow-y: auto;
}

.picture-product-viewer-card-top-shadow {
    width: 100%;
    position: fixed;
}

.picture-product-viewer-card.active {
    opacity: 1;
    visibility: visible;
}

.picture-product-viewer-card.inactive {
    opacity: 0;
    visibility: hidden;
}

.picture-product-viewer-options-cont {
    width: calc(100% - 35px);
    padding-right: 15px;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 5px;
    margin-left: 11px;

    button {
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 20px;
        font-size: 16px;

        img {
            width: 18px;
            width: 18px;
            padding-right: 10px;
        }
    }

    button:hover {
        background-color: rgba(131, 131, 131, 0.39);
    }
}

.picture-product-viewer-card-img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    padding-bottom: 20px;

    img {
        width: calc(100% - 40px);
        margin-left: -15px;
        max-width: 500px;
    }
}

.picture-product-viewer-btn-download {
    width: 140px;
    display: flex;
    justify-content: center;
}

.picture-product-viewer-btn-dark {
    background-color: #2b2b2b;
}

.picture-product-viewer-btn-light {
    background-color: #f7f7f7;
}

.skeleton-product {
    animation: skeleton-loading 0.7s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% { 
        background-color: hsla(0, 0%, 50%, 0.514);
    }
    100% {
        background-color: hsla(0, 0%, 50%, 0.116);
    }
}