.change-language-title-container {
    margin: 20px 25px 35px 30px;
}

.language-selection-input-sett {
    cursor: pointer;
    padding: 10px;
    width: 20px;
    height: 20px;
}

// HOVER
.change-language-btn-dark {
    background-color: var(--dark-secundary-bg);
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    color: white;
}

.change-language-btn-light {
    background-color: var(--light-secundary-bg);
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    color: black;
}

.change-language-btn-dark:hover {
    background-color: var(--dark-tertiary-bg);
}

.change-language-btn-light:hover {
    background-color: var(--light-tertiary-bg);
}


// NO HOVER
.change-language-btn-dark-no-hover {
    background-color: var(--dark-secundary-bg);
    color: white;
}

.change-language-btn-light-no-hover {
    background-color: var(--light-secundary-bg);
    color: black;
}

.change-language-btn-dark-no-hover:active {
    background-color: var(--dark-tertiary-bg);
}

.change-language-btn-light-no-hover:active {
    background-color: var(--light-tertiary-bg);
}

