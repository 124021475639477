.settiongs-title-option {
    display: flex;
    padding: 20px 0 30px 10px;
    margin-left: 20px;
    
    .settiongs-title-option-img-cont {
        width: 50px;
        height: 50px;
        margin-right: 20px;
        
        img {
            width: 100%;
            height: 100%;
            border-radius: 100px;
            object-fit: cover;
        }
    }
}

.settiongs-title-option-texts {
    line-height: 24px;
}