
.page-steps-to-connect-header {
    display: flex;
    width: 100%;
    align-items: center;

    img {
        width: 35px;
        margin: 30px 20px; 
        margin-left: 8%;
    }
}

.page-steps-to-connect-header-tittle {
    font-weight: bolder;
    font-size: 16px;
}

.page-steps-to-connect-header-tittle-body {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0px 25px 20px 30px;

    img {
        margin-top: 40px;
    }
}