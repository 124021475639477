.page-create-account-container {
    width: 100%;
    height: calc(100vh - 65px);
    flex-direction: column;
    display: flex;
    overflow: hidden;  
    overflow-y: auto;
}

.page-create-account-item {
    width: 100%;
    height: 100%;
}

.page-create-accout-header {
    display: flex;
    width: 100%;
    align-items: center;

    img {
        width: 35px;
        margin: 30px 20px; 
        margin-left: 8%;
    }
}

.pricing-cre-acc-card {
    padding: 20px;
    border-radius: 10px;
    min-width: 300px;
    max-width: 300px;
    margin-bottom: 20px;
    margin: 20px;

    ul {
        margin-bottom: 30px;
    }

    li {
        padding: 10px;
        list-style: none;
        
    }
}

.continue-cre-acc-button-container {
    padding: 10px;
    display: flex;
    width: 95%;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;

    color: white;
    background-color: var(--dark-primary);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);

    img {
        height: 20px;
        width: 20px;
    }

    p {
        margin-left: 15px;
    }
}

.continue-cre-acc-button-container:hover {
    background-color: #3f5266;
}

.pricing-cre-acc-title {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;

    h3 {
        padding: 10px;
    }

    p {
        padding: 5px;
    }
}

.link-to-portal-blank-dark {
    color: #00a3ee;
}

.link-to-portal-blank-dark:hover {
    color: rgb(255, 145, 145);
}

.link-to-portal-blank-light {
    color: #0000ee;
}

.link-to-portal-blank-light:hover {
    color: red;
}

.crea-accou-form-container-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.crea-accou-form-img {
    width: 60px;
    margin: 10px;
}

.page-create-account-tittle {
    font-weight: bolder;
    font-size: 16px;
}

.crea-accou-form-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.page-cre-acc-card-mobile-desktop {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 30px;
    max-width: 800px;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 40px;
}

.page-cre-acc-card-mobile-view {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-top: 30px;
    max-width: 400px;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 40px;
}

.page-cre-acc-form-desktop {
    display: flex;

    .form-cre-acc-desktop-first-section {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-right: 10px;
    }
    
    .form-cre-acc-desktop-second-section {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-left: 10px;
        border-left-style: solid;
        border-left-width: 0.14em;
        border-left-color: rgba(128, 128, 128, 0.137);
    }
}

.page-cre-acc-form-mobile {
    display: flex;
    flex-direction: column;

    .form-cre-acc-mobile-first-section {
        display: flex;
        flex-direction: column;
    }
    
    .form-cre-acc-mobile-second-section {
        display: flex;
        flex-direction: column;
    }
}


.input-cre-acc-description {
    margin: 10px;
}

.input-content-alert {
    border: solid 3px var(--alert-red);
}


.input-cre-acc-content {
    margin: 2px 10px 10px 10px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
}

.input-cre-acc-content-read-only {
    padding: 10px;
}

.outside-cre-acc-button-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;
    
    .outside-cre-acc-button-container-img {
        height: 20px;
        width: 20px;
    }

    .outside-cre-acc-button-container-text {
        margin-left: 15px;
    }
}