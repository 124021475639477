.terms-and-cond-container {
    margin-left: 30px;
    margin-right: 25px;
    margin-bottom: 50px;
}

.terms-and-cond-container-p-justify {
    text-align: justify;
}

.terms-and-cond-container-p-margin {
    margin-left: 20px;
}