.page-app-searcher-container {
    width: 100%;
    height: calc(100vh - 65px);
    flex-direction: column;
    display: flex;
    overflow: hidden; 
}

.page-app-searcher-container::-webkit-scrollbar {
    width: 0.7em;
}

.page-app-searcher-container::-webkit-scrollbar-thumb {
    background: #9ba3b85d;
    border-radius: 100vw;
}

.page-app-searcher-item {
    width: 100%;
    height: 100%;
}

.new-searcher-page-content {
    min-height: 200px;
    overflow: hidden;  
    overflow-y: auto;
}

.searcher-options-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.searcher-options-container-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin-bottom: 50px;
}