.no-found-container {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 120px;

    h3 {
        margin: 50px;
    }

    img {
        width: 130px;
        height: 130px;
    }

    div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px;
        margin-top: 80px;

        .no-found-page-botton {
            width: 100%;
            max-width: 200px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 100px;
            cursor: pointer;
            margin: 10px;
            text-decoration: none;

            box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
            background-color: var(--dark-primary);
            color: white;
        }

        .no-found-page-botton:hover {
            background-color: #3f5266;
        }
    }

}