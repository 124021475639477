.cash-in-cash-reg-textarea {
    
    height: 70px;
    min-height: 70px;
    resize: none;

    outline: none;
    width: calc(100% - 20px);
    border-radius:  10px;
    margin-top: 5px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    color: black;
    padding: 10px;
    font-size: 17px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cash-in-cash-reg-textarea::placeholder {
    color: rgba(177, 177, 177, 0.87);
    opacity: 1; /* Firefox */
}
  
.cash-in-cash-reg-textarea::-ms-input-placeholder { /* Edge 12 -18 */
    color: rgba(177, 177, 177, 0.87);
    opacity: 1; /* Firefox */
}