.main-options-container {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        margin-left: 5px;
    }
}

.main-options-link  {
    margin: 10px;
    padding: 10px;
    padding-right: 15px;
    text-decoration: none;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.main-options-link-light {
    background-color: var(--light-secundary-bg);
    color: black;

}

.main-options-link-dark {
    background-color: var(--dark-secundary-bg);
    color: white;
}

// .main-options-link-light-focus {
//     background-color: var(--focus-primary-bg);
//     color: black;
// }

.main-options-link-dark-focus {
    background-color: var(--dark-primary);
    color: white;
}

.main-options-link-light:hover {
    background-color: var(--light-tertiary-bg);
}

.main-options-link-dark:hover {
    background-color: var(--dark-tertiary-bg);
}