.sales-services-first-section {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.sales-services-first-item {
    display: flex;
    width: 100%;
    max-width: 960px;
    justify-content: space-around;
    flex-wrap: wrap;
}

.sales-services-second-section {
    padding: 15px;
    display: flex;
    width: calc(100% - 30px);
    justify-content: center;
}

.sales-filter-botton-main {
    display: flex;
}

.sales-filter-botton-container {
    margin-top: 20px;
    display: flex;
}

.filer-sales-btn {
    margin: 0px 10px 0px 20px;
    display: flex;
    justify-content: center;
    width: 100px;
    flex-direction: row;
    align-items: center;
    padding: 3px 0px 3px 10px;
    padding-right: 10px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    cursor: pointer;
}

// HOVER
.filer-sales-btn-dark {
    background-color: var(--dark-secundary-bg);
    color: white;
}

.filer-sales-btn-light {
    background-color: var(--light-secundary-bg);
    color: black;
}

.filer-sales-btn-dark:hover {
    background-color: var(--dark-tertiary-bg);
}

.filer-sales-btn-light:hover {
    background-color: var(--light-tertiary-bg);
}

// NO HOVER+
.filer-sales-btn-dark-no-hover {
    background-color: var(--dark-secundary-bg);
    color: white;
}

.filer-sales-btn-light-no-hover {
    background-color: var(--light-secundary-bg);
    color: black;
}

.filer-sales-btn-dark-no-hover:active {
    background-color: var(--dark-tertiary-bg);
}

.filer-sales-btn-light-no-hover:active {
    background-color: var(--light-tertiary-bg);
}


.sales-pointing-up {
    content: '';
    position: absolute;
    margin-top: 45px;
    margin-left: 50px;
    height: 15px;
    width: 15px;
    background: var(--light-secundary-bg);
    z-index: 0;
}

.sales-pointing-up.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transform: rotate(45deg);
    transition: 200ms ease;
}

.sales-pointing-up.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 200ms ease;
}

.dropdown-menu-sales {
    position: fixed;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 20px;
    background-color: var(--light-secundary-bg);
    border-radius: 8px;
    padding: 5px 10px 0 10px;
    width: 190px;
    max-height: calc(100vh - 245px);
    overflow: hidden;  
    overflow-y: scroll;
    min-height: 100px;
}

.dropdown-menu-sales::-webkit-scrollbar {
    width: 0.4em;
}

.dropdown-menu-sales::-webkit-scrollbar-thumb {
    background: #9ba3b81f;
    border-radius: 100vw;
}

.dropdown-menu-sales.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 200ms ease;
}

.dropdown-menu-sales.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 200ms ease;
}

.dropdown-menu-sales-dark {
    background-color: var(--dark-secundary-bg);
    color: white;
}

.dropdown-menu-sales-dark::before {
    background-color: var(--dark-secundary-bg);
}

.filter-date-input-sales {
    border: none;
    cursor: pointer;
    width: calc(100% - 25px);
    border-radius: 10px;
    font-size: 14px;
    margin-left: 5px;
    outline: none;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.filter-select-input-sales {
    border: none;
    cursor: pointer;
    border-radius: 10px;
    font-size: 14px;
    // margin-left: 3px;
    padding: 10px;
    margin-top: 5px;
    // margin-bottom: 10px;
}

.filter-button-action-div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.filter-button-btn-div {
    margin-bottom: 15px;
    margin-top: 20px;
    padding: 10px 30px;
    border-radius: 30px;
    color: white;
    border: none;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    background-color: var(--dark-primary);
    cursor: pointer;
}

.filter-button-btn-div:hover {
    background-color: #3f5266;
}

.filter-labels-inputs {
    margin-top: 10px;
}

.filter-sales-details-main-con {
    margin: 30px;
    margin-top: 15px;
    margin-bottom: -20px;
    display: flex;
    width: 350px;
    justify-content: space-between;
    border-radius: 20px;
}

.filter-sales-details-data {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 2px;
}

.filter-sales-details-clean-filter-btn {
    background-color: transparent;
    border: none;
    padding: 2px;
    padding-left: 20px;
    padding-right: 5px;
    border-radius: 0 20px 20px 0;
    outline: none;
    cursor: pointer;
}

.filter-sales-details-clean-filter-btn:hover {
    background-color: hsla(0, 0%, 50%, 0.11);
}

.sales-records-main-cont {
    width: calc(100% - 30px);
    padding: 15px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
