.inventory-uploading-products-main-cont {
    overflow: hidden;  
    overflow-y: auto;
}

.inventory-uploading-products-main-cont {
    display: flex;
    width: 100%;
    justify-content: center;
}

.inventory-uploading-products-cont {
    display: flex;
    width: calc(100% - 40px);
    max-width: 700px;
    margin: 50px 20px;
    flex-direction: column;
}

.inventory-uploading-products-drop-cont {
    margin-top: 40px;
    display: flex;
    width: 100%;
    justify-content: center;
}

.inventory-uploading-dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 400px;
    height: 200px;
    border: 3px dashed gray;
    padding: 20px;
}

.inventory-uploading-drag-over {
    background-color: rgba(98, 120, 144, 0.2117647059);
}

.inventory-uploading-input-con-f {
    display: flex;
    border: none;
    width: 170px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    background-color: var(--dark-primary);

    padding: 2px;

    white-space: nowrap;
    width: 100%;
    max-width: 200px;

    p {
        padding: 5px;
        margin-left: 10px;
    }

    input {
       display: none; 
    }

    img {
        width: 25px;
        padding: 5px;
        margin-right: 10px;
    }
}

.inventory-uploading-input-con-f:hover {
    background-color: #3f5266;
}

.inventory-uploading-input-con-f-no-hover {
    display: flex;
    border: none;
    width: 170px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    background-color: var(--dark-primary);

    padding: 2px;

    white-space: nowrap;
    width: 100%;
    max-width: 200px;

    p {
        padding: 5px;
        margin-left: 10px;
    }

    input {
       display: none; 
    }

    img {
        width: 25px;
        padding: 5px;
        margin-right: 10px;
    }
}

.inventory-uploading-input-con-f-no-hover:active {
    background-color: #3f5266;
}

.inventory-downloading-btn-product {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    background-color: rgba(128, 128, 128, 0.13);;
    max-width: 220px;
    white-space: nowrap;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 18px;
        height: 18px;
        margin-right: 15px;
    }
}

.inventory-downloading-btn-product:hover {
    background-color: rgba(128, 128, 128, 0.308);;
}

.inventory-downloading-btn-product-no-hover {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    background-color: rgba(128, 128, 128, 0.13);;
    max-width: 220px;
    white-space: nowrap;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 18px;
        height: 18px;
        margin-right: 15px;
    }
}

.inventory-downloading-btn-product-no-hover:active {
    background-color: rgba(128, 128, 128, 0.308);;
}