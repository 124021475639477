.invent-add-menu-btn {
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    background-color: var(--dark-primary);
    color: white;
    text-decoration: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 100px;
    width: 190px;
    margin-bottom: 25px;
    cursor: pointer;
}

.invent-add-menu-btn:hover {
    background-color: #3f5266;
}

.inven-first-secct-conte {
    display: flex;
    width: 100%;
    justify-content: center;
}

.inven-first-secct-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 1000px;
    margin-top: 18px;
    margin-bottom: 5px;
}

.inven-first-section-div-btn {
    display: flex;
    width: 275px;
    justify-content: space-between;
    padding-right: 15px;
}