.settiongs-individual-option {
    border-radius: 8px;
    text-decoration: none;
    display: flex;
    padding: 20px;
    margin: 10px;
    margin-left: 30px;
    margin-right: 25px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.settiongs-individual-option-dark:hover {
    background-color: var(--dark-tertiary-bg);
}

.settiongs-individual-option-light:hover {
    background-color: var(--light-tertiary-bg);
}

.settings-individual-option-icons {
    display: flex;
    align-items: center;

    img {
        width: 25px;
        height: 25px;
        margin-right: 20px;
    }
}

.settings-individual-option-arrow {
    width: 18px;
    height: 18px;
}

// Hover
.settiongs-individual-option-action-dark {
    background-color: var(--dark-secundary-bg);
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    color: white;
    border-radius: 8px;
    text-decoration: none;
    display: flex;
    padding: 20px;
    margin: 10px;
    margin-left: 30px;
    margin-right: 25px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.settiongs-individual-option-action-dark:hover {
    background-color: var(--dark-tertiary-bg);
}

.settiongs-individual-option-action-light {
    background-color: var(--light-secundary-bg);
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    color: black;
    border-radius: 8px;
    text-decoration: none;
    display: flex;
    padding: 20px;
    margin: 10px;
    margin-left: 30px;
    margin-right: 25px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.settiongs-individual-option-action-light:hover {
    background-color: var(--light-tertiary-bg);
}

// No hover
.settiongs-individual-option-action-dark-no-hover {
    background-color: var(--dark-secundary-bg);
    color: white;
    border-radius: 8px;
    text-decoration: none;
    display: flex;
    padding: 20px;
    margin: 10px;
    margin-left: 30px;
    margin-right: 25px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.settiongs-individual-option-action-dark-no-hover:active {
    background-color: var(--dark-tertiary-bg);
}

.settiongs-individual-option-action-light-no-hover {
    background-color: var(--light-secundary-bg);
    color: black;
    border-radius: 8px;
    text-decoration: none;
    display: flex;
    padding: 20px;
    margin: 10px;
    margin-left: 30px;
    margin-right: 25px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.settiongs-individual-option-action-light-no-hover:active {
    background-color: var(--light-tertiary-bg);
}