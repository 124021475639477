.products-indicators-mini-cards-metrics-background {
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    padding: 5px 10px;
    margin-top: 20px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // text-align: center;
}

.products-indicators-sales-num-cards-metrics-background {
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    padding: 5px 10px;
    margin-top: 25px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
}

.products-indicators-sales-num-select-period {
    display: flex;
    border: 2px solid transparent;
    background-color: rgba(128, 128, 128, 0.055);
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    padding: 3px 5px 3px 5px;
    cursor: pointer;
    height: 25px;

    label {
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    input {
        width: 18px;
        height: 18px;
        margin: 3px;
        margin-right: 10px;
        cursor: pointer;
    }
}

.products-indicators-sales-num-select-period-hover {
    background-color: #62789036;
    border: 2px solid #627890a9;
}

.products-indicators-sales-num-select-period:hover {
    background-color: #62789036;
}

.products-indicators-statistics-sales-input {
    outline: none;
    width: calc(100% - 20px);
    border-radius: 10px;
    margin-top: 5px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.products-indicators-statistics-sales-input::placeholder {
    color: rgba(128, 128, 128, 0.678);
    opacity: 1; /* Firefox */
}
  
.products-indicators-statistics-sales-input::-ms-input-placeholder { /* Edge 12 -18 */
    color: rgba(128, 128, 128, 0.678);
}

.products-indicators-statistics-sales-input-dark {
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.products-indicators-statistics-sales-input-light {
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.products-indicators-statistics-sales-input-dark:focus {
    border: 1px solid rgb(108, 167, 221);
}

.products-indicators-statistics-sales-input-light:focus {
    border: 1px solid rgba(108, 166, 221, 0.884);
}

.pros-inds-stats-sales-only-one-line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pros-inds-stats-sales-p-copy-hover {
    font-size: 16px;
    padding: 3px 8px;
    cursor: pointer;
    border-radius: 15px;
    line-height: 1.5; 
}

.pros-inds-stats-sales-p-copy-hover:hover {
    background-color: rgba(128, 128, 128, 0.13);
}