.settings-container-header {
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
}

.settings-container-header-img {
    width: 20px;
    margin-right: 15px;
}

.settings-text-link-header {
    text-decoration: none;
}

.settings-text-link-header-dark-text {
    color: rgba(255, 255, 255, 0.550);
}

.settings-text-link-header-light-text {
    color: rgba(0, 0, 0, 0.550);
}

.settings-text-link-header-dark-text:hover {
    color: white;
}

.settings-text-link-header-light-text:hover {
    color: black;
}