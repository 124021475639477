.bank-account-to-container-paypal {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-user-select: text;
    -webkit-touch-callout: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.bank-account-to-first-section {
    width: 100%;
    max-width: 450px;
    display: flex;
    justify-content: space-between;
}

.bank-account-to-bank-logo-desp {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bank-account-to-bank-logo {

    img {
        width: 40px;
    }
}

.settings-bills-paypal-buttons {
    display: flex;
    margin-top: 50px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
}

.settings-bills-paypal-button {
    padding: 10px 35px;
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    text-decoration: none;
    margin: 5px;
    margin-bottom: 30px;
    border-radius: 100px;
    border: none;
    background-color: #005FD2;
    color: white;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    

    img {
        width: 18px;
        margin-left: 10px;
    }
}

.settings-bills-paypal-button:hover {
    background-color: #003D8D;
}

