.inventory-table-container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
}

.inventory-table-section-1 {
  height: calc(100vh - 65px);
  border-top-left-radius: 10px;
}

.inventory-table-create-table {
    border-spacing: 0;
    z-index: 0;
}

.inventory-table-create-th {
    padding: 5px;
    background-color: #627890;
    color: white;
    top: 0;
}

.inventory-table-create-td-content-editable {
    padding: 10px 8px;
    width: calc(100% - 20px);
    border: 2px solid transparent;
    overflow: auto;
    resize: none;
    margin-bottom: -4px;
}

.inventory-table-create-td-content-editable:focus {
    border: 2px solid rgb(108, 167, 221);
}

.inventory-table-create-td-content-select {
    padding: 0px 8px 20px 8px;
    width: calc(100%);
    border: 2px solid transparent;
    overflow: auto;
    resize: none;
}

.inventory-table-create-td-content-select:focus {
    border: 2px solid rgb(108, 167, 221);
}

.inventory-table-create-td-sticky-dark {
    background-color: #353535;
}

.inventory-table-create-td-sticky-light {
    background-color: #eeeeee;
}

.inventory-table-create-td-sticky {
    left: 0;
    z-index: 2;
}

.inventory-table-create-th-sticky {
    position: sticky;
    left: 0;
    z-index: 1;
}

.inventory-table-create-checkbox {
    min-width: 18px;
    min-height: 18px;
    cursor: pointer;
}