.settings-action-required {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 25px;
    margin-bottom: 40px;
}

.settings-action-required-cont-img {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.settings-action-required-cont-img-div {
    width: 80px;
    height: 80px;
}

.settings-action-required-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.settings-action-required-cont-title {
    display: flex;
    width: calc(100% - 20px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.settings-action-required-cont-despcrition {
    width: 100%;
    max-width: 380px;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.settings-action-required-cont-despcrition-cont {
    padding: 10px;
}

.settings-action-required-cont-butt {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 10px;
}

.settings-action-required-butt {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;

    white-space: nowrap;

    color: white;
    background-color: var(--dark-primary);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    
    .settings-action-required-butt-img {
        height: 20px;
        width: 20px;
    }

    .settings-action-required-butt-text {
        margin-left: 10px;
        margin-right: 10px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.settings-action-required-butt:hover {
    background-color: #3f5266;
}