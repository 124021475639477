.settings-edict-profile-container {
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    margin-top: 5px;
    margin-left: 30px;
    margin-right: 25px;
    margin-bottom: 40px;
    justify-content: space-around;
}

.settings-edict-profile-container-picture-div {
    width: 100px;
    height: 100px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.settings-edict-profile-container-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
}

.settings-edict-profile-container-first {
   min-width: 225px;
   min-height: 210px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-bottom: 10px;
}

.settings-edict-profile-container-second {
    width: 270px;
    margin-top: 50px;
}

.settings-edict-profile-div-buttons {
    display: flex;
    width: 100%;
    height: 70px;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}

.settings-edict-profile-div-text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    label {
        white-space: nowrap;
    }
}

.settings-edict-profile-input-text {
    padding: 10px;
    border-radius: 10px;
    margin-left: 10px;
    border: none;
    width: 160px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
}

.settings-edict-profile-input-con-f {
    display: flex;
    border: none;
    width: 170px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    background-color: rgba(128, 128, 128, 0.13);

    p {
        padding: 5px;
        margin-left: 10px;
    }

    input {
       display: none; 
    }

    img {
        width: 25px;
        padding: 5px;
        margin-right: 10px;
    }
}

.settings-edict-profile-input-con-f:hover {
    background-color: rgba(128, 128, 128, 0.308);
}

.settings-edict-profile-input-con-f-no-hover {
    display: flex;
    border: none;
    width: 170px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    background-color: rgba(128, 128, 128, 0.13);

    p {
        padding: 5px;
        margin-left: 10px;
    }

    input {
       display: none; 
    }

    img {
        width: 25px;
        padding: 5px;
        margin-right: 10px;
    }
}

.settings-edict-profile-input-con-f-no-hover:active {
    background-color: rgba(128, 128, 128, 0.308);
}

.settings-edict-profile-input-file {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

.settings-edict-profile-container-button {
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.settings-edict-profile-container-button-active {
    background-color: var(--dark-primary);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.settings-edict-profile-container-button-active:hover {
    background-color: #3f5266;
}

.settings-edict-profile-container-button-active-disable {
    background-color: #62789079;
    color: rgba(255, 255, 255, 0.788);
}

.settings-edict-profile-container-button-delete-active {
    background-color: rgba(255, 42, 42, 0.863);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.settings-edict-profile-container-button-delete-active:hover {
    background-color: rgba(134, 26, 26, 0.884);
}

.settings-edict-profile-container-button-delete-disable {
    background: rgba(185, 95, 95, 0.5);
    color: rgba(255, 255, 255, 0.788);
}

.settings-edict-profile-yes-img-big-cont {
    display: flex;
    width: 100%;
    height: 140px;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.set-edi-pro-yes-img-big-con-div {
    width: 140px; 
    height: 140px;
    cursor: pointer;
}

.set-edi-pro-yes-img-big-con-div:hover {
    img {
        opacity: 0.8;
    }
}

.settings-edict-profile-yes-img-big {
    background-color: rgba(128, 128, 128, 0.171);
    border-radius: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.settings-edict-profile-no-img-big-cont {
    display: flex;
    width: 100%;
    height: 140px;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.set-edi-pro-no-img-big-con-div {
    width: 140px; 
    height: 140px;
    background-color: rgba(128, 128, 128, 0.171);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.settings-edict-profile-not-img-big {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.edict-cust-prof-button-hover {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--dark-primary);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }

}

.edict-cust-prof-button-hover:hover {
    background-color: #3f5266;
}

.edict-cust-prof-button-no-hover {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--dark-primary);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }

}

.edict-cust-prof-button-no-hover:active {
    background-color: #3f5266;
}