.navigation-container-menuBurger { 
    margin: 10px;
    padding: 3px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    
    .navigation-container-menuBurger-item {
        padding: 8px;
        width: 18px;
        height: 18px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-navigation {
            border-radius: 5px;
        }
    }
}

.side-menu-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top:  0;
    padding: 0 10px 0 10px;
    min-width: 260px;
    max-width: 75%;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    height: 100vh;
    z-index: 5;
    overflow: hidden;
    overflow-y: scroll;
    max-height: 100vh;

    .side-menu-close-button {
        margin: 15px;
        background-color: transparent;
        border: none;
        border-radius: 50px;
        width: 40px;
        min-width: 40px;
        height: 40px;
        min-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .side-menu-close-img {
            width: 14px;
            height: 14px;
        }
    }
}

.side-menu-container::-webkit-scrollbar {
    width: 0.4em;
}

.side-menu-container::-webkit-scrollbar-thumb {
    background: #9ba3b81f;
    border-radius: 100vw;
}

.side-menu-container.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    transition: 200ms ease;
}

.side-menu-container.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-10px);
    transition: 100ms ease;
}

.side-menu-options {
    //margin-bottom: 250px;
    
    .side-menu-item {
        list-style: none;  
        border-radius: 10px;
        margin: 10px;
        
        .side-menu-form {

            .side-menu-form-input-container {
                padding: 5px;
                padding-left: 10px;
                display: flex;
                align-items: center;
            }
            
            input {
                cursor: pointer;
                padding: 10px;
                width: 20px;
                height: 20px;
            }

            label {
                cursor: pointer;
                font-size: 16px;
                padding: 10px 50px 10px 10px;
            }
        }
    }

    .side-menu-item.light {
        background: var(--light-tertiary-bg);
    }
    
    .side-menu-item.dark {
        background: var(--dark-tertiary-bg);
    }
    .side-menu-item.closed {
        background: transparent;
    }

    .side-menu-form.open {
        opacity: 1;
        height: auto;
    }

    .side-menu-form.closed {
        transition: opacity 1s ease-out;
        opacity: 0;
        height: 0;
        overflow: hidden;
    }
}

.navigation-container-menuBurger-light {
    background-color: var(--light-secundary-bg);
    color: black;
}

.navigation-container-menuBurger-dark {
    background-color: var(--dark-secundary-bg);
    color: white;
}

.navigation-container-menuBurger-light:hover {
    background-color: var(--light-tertiary-bg);
}

.navigation-container-menuBurger-dark:hover {
    background-color: var(--dark-tertiary-bg);
}