.settings-subcription {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    min-height: 200px;
    margin: 10px;
    margin-left: 30px;
    margin-right: 25px;
    margin-bottom: 40px;
}

// Container #1
.settings-subcription-cont-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 20px;

    p {
        padding: 10px;
    }
}

.settings-subcription-cont-img-div {
    width: 100px;
    height: 100px;
}

.settings-subcription-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

// Container #2
.settings-subcription-cont-title {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

// Container #3
.settings-subcription-cont-butt {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
}

.settings-subcription-cont-butt-link {
    width: 280px;
}

.settings-subcription-butt {
    padding: 10px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    width: calc(100% - 30px) ;
    max-width: 280px;
    margin: 15px;

    color: white;
    background-color: var(--dark-primary);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
}

.settings-subcription-butt:hover {
    background-color: #3f5266;
}

.settings-subcription-cont-adv {
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.settings-subcription-cont-adv-p-div {
    width: 260px;
    margin-top: 35px;
}

.settings-subcription-cont-adv-p {
    padding: 5px;
}

.settings-subcription-cont-adv-ul {
    li {
        margin: 20px;
    }
}