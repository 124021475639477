.settings-leaving-card-container {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 15px;
    margin-bottom: 40px;
}

.settings-leaving-card-container-div {
    display: flex;
    align-items: center;
}

.settings-leaving-card-img {
    width: 20px;
    height: 20px;
    padding: 10px 15px 10px 10px;
}

.settings-leaving-card-li {
    padding: 10px;
    margin-left: 15px;
    text-align: center;
}

.settings-leaving-card-page-div {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 10px;
}

.settings-leaving-card-page-form {
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
}

.settings-leaving-card-input-description {
    margin: 10px;
}

.settings-leaving-card-input-content {
    margin: 2px 10px 10px 10px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
}

.settings-leaving-card-input-content-alert {
    border: solid 3px var(--alert-red);
}

.settings-leaving-card-button-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin: 10px;
    margin-top: 20px;
    text-decoration: none;

    color: white;
    background-color: var(--dark-primary);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
}

.settings-leaving-card-button-container-img {
    height: 20px;
    width: 20px;
}

.settings-leaving-card-button-container-text {
    margin-left: 15px;
}

.settings-leaving-card-button-container:hover {
    background-color: #3f5266;
}