.page-container {
    width: 100%;
    height: calc(100vh - 65px);
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;  
    overflow-y: auto;

    p {
        margin: 30px;
    }

    .maintencance-icon {
        width: 100px;
        height: 100px;
    }
}

.page-container::-webkit-scrollbar {
    width: 0.7em;
}

.page-container::-webkit-scrollbar-thumb {
    background: #9ba3b85d;
    border-radius: 100vw;
}

.page-body {
    display: flex;
    height: calc(100vh - 65px);
}

.mobile-control-button-space {
    height: 75px;
}

.desktop-control-button-space {
    height: 5px;
}