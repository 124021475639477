.products-individual-product-card {    
    display: flex;
    flex-direction: column;
    min-width: 140px;
    max-width: 140px;
    margin: 10px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    text-decoration: none;
    max-height: 260px;
    overflow-x: hidden;
}

.products-individual-product-card-dark {
    color: white;
    background-color: var(--dark-secundary-bg);
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.products-individual-product-card-light {
    color: black;
    background-color: var(--light-secundary-bg);
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.products-individual-product-card-dark:hover {
    background-color: var(--dark-tertiary-bg);
}

.products-individual-product-card-light:hover {
    background-color: var(--light-tertiary-bg);
}

.products-individual-product-id {
    padding: 4px;
    font-size: 12px;
    font-weight: bold;
}

.products-individual-product-yes-img-cont {
    display: flex;
    width: 100%;
    height: 140px;
    justify-content: center;
    align-items: center;
}

.products-individual-product-no-img-cont {
    display: flex;
    width: 100%;
    height: 140px;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.171);
}

.products-individual-product-yes-img {
    border-radius: 0px;
    background-color: rgba(128, 128, 128, 0.171);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.products-individual-product-no-img {
    width: 70px;
    height: 70px;
    padding: 10px 0;
}

.products-individual-product-name-div {
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
}

.products-individual-product-name {
    padding: 4px;
    font-weight: bold;
}

.products-individual-product-price {
    padding: 4px;
    padding-bottom: 8px;
    font-weight: bold;
    white-space: nowrap;
}

.prod-card-info-out-of-stock-span-cont {
    font-weight: bold;
    background-color: rgba(189, 47, 47, 0.822);
    padding: 0px 8px 2px 8px;
    color: white;
    font-size: 14px;
    text-align: center;
    margin-top: -20.4px;
}

.recently-opened-card-div {
    cursor: pointer;
    text-decoration: none;
    padding: 5px;
    border-radius: 10px;
    width: calc(100% - 30px);
    max-width: 600px;
    margin: 15px 15px 8px 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    border: none;
    // height: 46.4px;
    // max-height: 46.4px;
    min-height: 46.4px;
}

// *********************************************************************

// Hover
.recently-opened-focus-card-div-light-HOVER {
    color: black;
    background-color: #62789036;
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.recently-opened-focus-card-div-light-HOVER:hover {
    background-color: var(--light-tertiary-bg);
}

// No Hover
.recently-opened-focus-card-div-light-NOHOVER {
    color: black;
    background-color: #62789036;
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.recently-opened-focus-card-div-light-NOHOVER:active {
    background-color: var(--light-tertiary-bg);
}

// Hover
.recently-opened-focus-card-div-dark-HOVER {
    color: white;
    background-color: #62789036;
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.recently-opened-focus-card-div-dark-HOVER:hover {
    background-color: var(--dark-tertiary-bg);
}

// No Hover
.recently-opened-focus-card-div-dark-NOHOVER {
    color: white;
    background-color: #62789036;
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.recently-opened-focus-card-div-dark-NOHOVER:active {
    background-color: var(--dark-tertiary-bg);
}

// *********************************************************************

// Hover
.recently-opened-card-div-light-HOVER {
    color: black;
    background-color: var(--light-secundary-bg);
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.recently-opened-card-div-light-HOVER:hover {
    background-color: var(--light-tertiary-bg);
}

// No Hover
.recently-opened-card-div-light-NOHOVER {
    color: black;
    background-color: var(--light-secundary-bg);
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.recently-opened-card-div-light-NOHOVER:active {
    background-color: var(--light-tertiary-bg);
}

// Hover
.recently-opened-card-div-dark-HOVER {
    color: white;
    background-color: var(--dark-secundary-bg);
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.recently-opened-card-div-dark-HOVER:hover {
    background-color: var(--dark-tertiary-bg);
}

// No Hover
.recently-opened-card-div-dark-NOHOVER {
    color: white;
    background-color: var(--dark-secundary-bg);
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.recently-opened-card-div-dark-NOHOVER:active {
    background-color: var(--dark-tertiary-bg);
}

.recently-opened-section1 {
    margin-left: 10px;
    overflow: hidden;
    // white-space: nowrap;
    display: flex;
    flex-direction: column;
    flex-grow: 4;
    align-items: self-start;
}

.recently-opened-id-name {
    font-size: 16px;
    font-weight: bold;
}

.recently-opened-section2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 2px;
    padding-right: 10px;
    padding-left: 5px;
    white-space: nowrap;
    min-width: 65px;
}

.recently-opened-hours {
    font-size: 14px;
    font-weight: bold;
}

.recently-opened-right-now {
    background-color: #627890d2;
    color: white;
    font-weight: bold;
    padding: 0 10px;
    border-radius: 10px;
}

.customer-card-texto-overflow {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Número de líneas que deseas mostrar */
    text-align: start;
    line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    height: calc(1.4em * 1); /* Ajusta la altura según el número de líneas */
    line-height: 1.5em; /* Ajusta según la altura de la línea de tu fuente */
}