.cancel-subs-form-page-container {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 60px;
    align-items: center;
}

.cancel-subs-reasons-del-container {
    margin:  0 30px 0 50px;
    width: 100%;
}

.cancel-subs-reasons-radio-input {
    margin: 15px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    

    input {
        cursor: pointer;
        margin: 0;
        height: 18px;
        width: 18px;
    }

    label {
        cursor: pointer;
        margin: 0;
        padding: 5px;
    }
}

.cancel-subs-reasons-text-input {
    margin-top: 15px;
    margin-bottom: 10px;
    width: calc(100% - 45px);
    border-radius: 10px;
    height: 80px;
    border: none;
    padding: 10px;
    resize: none;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
}

.cancel-subs-form-page-item {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cancel-subs-question-del-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0 10px 0;

    .cancel-subs-question-del-img {
        width: 70px;
        height: 70px;
    }

    p {
        margin-top: 13px;
    }
}

.cancel-subs-form-page-description {
    margin: 10px;
    margin-left: 13px;
}

.cancel-subs-form-page-input {
    margin: 2px 10px 10px 10px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
}

.cancel-subs-form-page-alert {
    border: solid 3px var(--alert-red);
}

.cancel-subs-form-page-button {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px;
    background-color: rgba(247, 37, 37, 0.89);;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;
    color: white;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    
    .cancel-subs-form-page-img {
        height: 20px;
        width: 20px;
    }

    .cancel-subs-form-page-text {
        margin-left: 15px;
        padding: 2px;
    }
}

.cancel-subs-form-page-button:hover {
    background-color: rgba(134, 26, 26, 0.884);
}