.labelAdvanceOptionProduct {
    padding: 5px;
    border-radius: 10px;
    width: auto;
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 10px;

    p {
        display: flex;
        align-items: center;

        label {
            padding: 4px 10px 4px 15px;
        }
    }
}

.product-advanced-opts-dashed-line {
    border: none;
    border-top: 0.2px solid rgba(129, 129, 129, 0.288);
    color: black;
    background-color: transparent;
    height: 0.2px;
    width: 100%;
}