.auth-view-card-container {
    text-decoration: none;
    width: 100%;
    display: flex;
    margin: 20px 0 20px 0;
    border-radius: 100px;
    border: 1px solid transparent;
    cursor: pointer;
}

.auth-view-card-container-dar {
    color: white;
}

.auth-view-card-container-lig {
    color: black;
}

.auth-view-card-container:hover {
    background-color: #62789036;
    border: 1px solid #627890a9;
}

.auth-view-card-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    margin-left: 19px;

    p {
        white-space: nowrap;
        display: flex;
        align-items: center;

        img {
            width: 18px;
            height: 18px;
            margin-right: 8px;
        }
    }
}

.auth-user-view-mini-profile-yes-img-big-cont {
    display: flex;
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    padding: 0.5px;
    justify-content: center;
    align-items: center;
}

.aut-use-view-mini-pro-yes-img-big-con-div {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
}

.settings-auth-user-mini-name-img {
    width: 50px;
    height: 50px;
    padding: 0.5px;
    border-radius: 100px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100px;
    }
}

.settings-auth-user-mini-name-error-img {
    width: 50px; 
    height: 50px;
    padding: 0.5px;
    border-radius: 100%;

    background-color: rgba(128, 128, 128, 0.171);
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
        width: 50px;
        height: 50px;
        border-radius: 0px;
    }
}