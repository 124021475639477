// ************************** Scroll Element
.new-customer-form-container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: calc(100vh - 65px);
    overflow: hidden;  
    overflow-y: auto;
}

.new-customer-form {
    margin: 20px;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
}

.new-customer-title {
    font-size: 18px;
    margin: 10px 10px 10px 0;
}

.new-customer-label {
    margin-top: 20px;
}

.new-customer-helper-label {
    color: transparent;
    margin-top: -2px;
    font-size: 1px;
}

.new-customer-input-alert {
    border: solid 3px rgba(255, 0, 0, 0.486);
}

.new-customer-input {
    outline: none;
    width: calc(100% - 20px);
    border-radius:  10px;
    margin-top: 5px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    font-weight: 300; 
    color: black;
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.new-customer-input::placeholder {
    color: rgba(128, 128, 128, 0.678);
    opacity: 1; /* Firefox */
}
  
.new-customer-input::-ms-input-placeholder { /* Edge 12 -18 */
    color: rgba(128, 128, 128, 0.678);
}

.new-customer-input-dark {
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.new-customer-input-light {
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.new-customer-input-dark:focus {
    border: 1px solid rgb(108, 167, 221);
}

.new-customer-input-light:focus {
    border: 1px solid rgba(108, 166, 221, 0.884);
}

.new-custmer-textarea-address {
    margin-top: 8px;
    margin-bottom: 10px;
    width: calc(100% - 20px);
    outline: none;
    height: 70px;
    min-height: 70px;
    border-radius: 10px;
    padding: 10px;
    resize: none;
    font-size: 16px;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
}

.new-custmer-textarea-address::placeholder {
    color: rgba(128, 128, 128, 0.678);
    opacity: 1; /* Firefox */
}
  
.new-custmer-textarea-address::-ms-input-placeholder { /* Edge 12 -18 */
    color: rgba(128, 128, 128, 0.678);
}

.new-custmer-textarea {
    margin-top: 8px;
    margin-bottom: 10px;
    width: calc(100% - 20px);
    outline: none;
    height: 110px;
    min-height: 110px;
    border-radius: 10px;
    padding: 10px;
    resize: none;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
}

.new-custmer-textarea::placeholder {
    color: rgba(128, 128, 128, 0.678);
    opacity: 1; /* Firefox */
}
  
.new-custmer-textarea::-ms-input-placeholder { /* Edge 12 -18 */
    color: rgba(128, 128, 128, 0.678);
}

.new-custmer-textarea-dark {
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.new-custmer-textarea-light {
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.new-custmer-textarea-dark:focus {
    border: 1px solid rgb(108, 167, 221);
}

.new-custmer-textarea-light:focus {
    border: 1px solid rgba(108, 166, 221, 0.884);
}

.new-customer-bottons-container {
    border-top-style: solid;
    border-top-width: 0.14em;
    border-top-color: rgba(128, 128, 128, 0.137);
    display: flex;
    width: 100%;
    justify-content: center;
}

.new-customer-bottons-container2 {
    margin-top: 15px;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.new-customer-bottons-button-red {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    color: white;
    background-color: rgba(255, 42, 42, 0.863);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 14px;
        height: 14px;
        margin-right: 10px;
        
    }
}

.new-customer-bottons-button-red:hover {
    background-color: rgba(134, 26, 26, 0.884);
}

.new-customer-bottons-button-save {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--dark-primary);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }

}

.new-customer-bottons-button-save:hover {
    background-color: #3f5266;
}