.coll-payme-me-cont {
    margin-left: 15px;
}

.coll-payme-me-btn-left {
    padding: 8px;
    border: none;
    min-width: 120px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.coll-payme-me-btn-right {
    padding: 8px;
    border: none;
    min-width: 120px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.coll-payme-me-btn-active-dark {
    color: white;
    background-color: #6278906c;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 2.5px solid #627890;
    border-left: 1.5px solid #627890;
    border-right: 1.5px solid #627890;
    font-weight: bold;
}

.coll-payme-me-btn-active-light {
    color: black;
    background-color: rgba(221, 221, 221, 0.842);
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 2.5px solid #627890a2;
    border-left: 1.5px solid #627890a2;
    border-right: 1.5px solid #627890a2;
    font-weight: bold;
}

.coll-payme-me-btn-inactive-light {
    cursor: pointer;
    color: black;
    background-color: rgb(247, 247, 247);
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 2.5px solid transparent;
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
}

.coll-payme-me-btn-inactive-dark {
    cursor: pointer;
    color: white;
    background-color: rgb(95, 95, 95);
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 2.5px solid transparent;
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
}

.coll-payme-me-btn-inactive-light:hover {
    background-color: #62789027;
}

.coll-payme-me-btn-inactive-dark:hover {
    background-color: #6278906c;
}

.cash-in-cash-reg-bottons-button-disable {
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }

}