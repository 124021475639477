.settings-bill-history {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 10px;
    margin-left: 30px;
    margin-right: 25px;
    margin-bottom: 20px;
}

.settings-bill-history-empty-con {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    img {
        width: 70px;
        margin: 20px;
        margin-top: 30px;
    }
}

.settings-bill-history-item-hr {
    margin: 10px 0px 20px 0px;
}

.settings-bill-history-item-con {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-size: 14px;
    }
}

.settings-bill-history-open-butt {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 5px;
    border-radius: 100px;
    border: none;
    width: 130px;
    cursor: pointer;
    text-decoration: none;

    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    background-color: var(--dark-primary);
    color: white;

    img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
    }
}

.settings-bill-history-open-butt:hover {
    background-color: #3f5266;
}