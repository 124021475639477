.notifications-list-container-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    width: 100%;
    height: calc(100vh - 125px);
    min-height: calc(100vh - 125px);
    overflow: hidden;  
    overflow-y: auto;
}

// HOVER
.notification-card-in-list {
    display: flex;
    background-color: transparent;
    border: none;
    margin: 13px;
    padding: 8px;
    width: calc(100% - 40px);
    max-width: 650px;
    transition: background-color 0.2s cubic-bezier(0.05,0,0,1);
    cursor: pointer;
    border-radius: 10px;
}

.notification-card-in-list:hover {
    background-color: rgba(128, 128, 128, 0.171);
}

// NO HOVER
.notification-card-in-list-no-hover {
    display: flex;
    background-color: transparent;
    border: none;
    margin: 13px;
    padding: 8px;
    width: calc(100% - 40px);
    max-width: 650px;
    transition: background-color 0.2s cubic-bezier(0.05,0,0,1);
    cursor: pointer;
    border-radius: 10px;
}

.notification-card-in-list-no-hover:active {
    background-color: rgba(128, 128, 128, 0.171);
}

.nofification-card-in-list-text-div {
    flex-grow: 4;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.nofification-card-in-list-element-date {
    font-size: 14px;
    font-weight: bold;
    text-align: start;
}

.nofification-card-in-list-element-new {
    color: black;
    font-weight: 400;
    padding: 5px;
}


.nofification-card-in-list-element-text {
    margin-top: 8px;
    text-align: start;
}

.nofification-card-in-list-input-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    min-width: 40px;
    height: 100%;

    input {
        min-width: 18px;
        min-height: 18px;
        margin-right: 10px;
        cursor: pointer;
    }
}

.nofification-card-in-list-img-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    min-width: 40px;
    height: 100%;

    img {
        width: 18px;
        height: 18px;
        margin-left: 10px;
    }
}

.nofification-card-in-list-con-botton-space {
    min-height: 60px;
}

.nofification-card-in-list-con-buttons-elem {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    color: white;

    position: fixed;

    bottom: 10px;

    display: flex;

    justify-content: center;
    text-decoration: none;
    align-items: center;
    padding: 10px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 8px;
    white-space: nowrap;
    min-width: 80px;

    img {
        width: 16px;
        height: 16px;
    }
}

.nofification-card-in-list-con-buttons-elem-hover {
    background-color: var(--dark-primary);
}

.nofification-card-in-list-con-buttons-elem-no-hover {
    background-color: var(--dark-primary);
}

.nofification-card-in-list-con-buttons-elem-hover:hover {
    background-color: #3f5266;
}

.nofification-card-in-list-con-buttons-elem-no-hover:active {
    background-color: #3f5266;
}