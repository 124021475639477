.page-container-outside {
    width: 100%;
    height: calc(100vh - 65px);
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        margin: 30px;
    }

    .maintencance-icon {
        width: 100px;
        height: 100px;
    }
}

.outside-nav-space {
    display: block;
    width: 100%;
    min-height: 63px;
}

.login-page-container {
    width: 100%;
    flex-direction: column;
    display: flex;
    
    .login-page-card-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
        height: calc(100vh - 65px);
        overflow: hidden;
        overflow-y: auto;
    }
}

.login-page-card-mobile-view {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin-top: 30px;
    max-width: 400px;
    padding-top: 20px;
    border-radius: 10px;

    h3 {
        margin: 10px;
    }
}

.login-page-card-mobile-desktop {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin-top: 30px;
    max-width: 800px;
    padding: 20px;
    border-radius: 10px;

    h3 {
        margin: 10px;
    }
}

.login-logo-container {
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.outside-header-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    text-decoration: none;
}

.login-logo-container-img {
    width: 45px;
    height: 45px;
}

.outside-header {
    margin-left: 22px;
    
    h3 {
        font-size: 20px;
    }

    p {
        padding-right: 35px;

    }
}

.login-page-card-title {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    min-height: 65px;

    h3 {
        padding: 0;
    }
}

.login-page-form-mobile {
    display: flex;
    flex-direction: column;

    .form-mobile-first-section {
        display: flex;
        flex-direction: column;
    }
    
    .form-mobile-second-section {
        display: flex;
        flex-direction: column;
    }
}

.login-page-form-desktop {
    display: flex;

    .form-desktop-first-section {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-right: 10px;
    }
    
    .form-desktop-second-section {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-left: 10px;
        border-left-style: solid;
        border-left-width: 0.14em;
        border-left-color: rgba(128, 128, 128, 0.137);
    }
}

.or-span {
    width: 100%;
    text-align: center;
    margin:  15px 0 15px 0;
}

.input-description {
    margin: 10px;
}

.input-description-email {
    margin: 10px;
    margin-top: 0px;
}

.input-content {
    margin: 2px 10px 10px 10px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
}

.input-content-alert {
    border: solid 3px var(--alert-red);
}

.forgot-password-span {
    margin: 10px;
    padding: 10px;
    margin-left: auto;
    border-radius: 20px;
}

.creating-account-container {
    min-height: 300px;
}

.verify-data-email {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 220px;
    justify-content: space-around;
}

.outside-button-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin: 10px;
    margin-top: 20px;
    text-decoration: none;

    color: white;
    background-color: var(--dark-primary);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    
    .outside-button-container-img {
        height: 20px;
        width: 20px;
    }

    .outside-button-container-text {
        margin-left: 15px;
    }
}

.outside-button-container:hover {
    background-color: #3f5266;
}

.width-100 {
    width: 100%;
}

.email-verification-address {
    display: flex;
    align-items: center;
}

.recover-passwor-email {
    width: 24px;
    height: 24px;
    margin-left: 10px;
}

.recover-password-p {
    margin: 10px;
}

.email-verification-h4 {
    margin: 10px;
}

.verify-data-card-buttons {
    display: flex;
    width: 100%;

    button {
        width: 100%;
    }
}

.verify-email-cancel-dark {
    background-color:  rgba(247, 37, 37, 0.89);
    color: white;
}

.verify-email-cancel-light {
    background-color: rgb(253, 196, 196);
    color: rgb(0, 0, 0);
}

.recover-password-h4 {
    margin: 10px;
    text-align: justify;
    margin-bottom: 25px;
}

.email-verification-counter {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: justify;
}

.email-verification-counter-img {
    width: 100px;
    height: 100px;
    margin-right: 15px;
}

.email-verification-counter-h1 {
    margin: 20px;
}

.email-verification-counter-p {
    margin: 20px;
}

.re-send-verify-email {
    display: flex;
    flex-direction: column;
}

.verify-data-card-text {
    margin: 10px;
    text-align: justify;
}

.verify-data-password {
    display: flex;
    flex-direction: column;

    h4 {
        text-align: center;
        font-size: 17px;
    }
}

.verify-data-invalid {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 80px;
        height: 80px;
        margin: 25px;
    }
}

.outside-button-container-agree {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin: 10px;
    margin-top: 20px;
    text-decoration: none;

    color: white;
    background-color: var(--dark-primary);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    
    .outside-button-container-img-agree {
        height: 20px;
        width: 20px;
    }

    .outside-button-container-text-agree {
        margin-left: 15px;
    }
}

.outside-button-container-agree:hover {
    background-color: #3f5266;
}

.button-disable-bc-cust-did-not-agree-terms-cond {
    cursor: inherit;
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background-color: #62789079;
    color: rgba(255, 255, 255, 0.550);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    margin: 10px;
    margin-top: 20px;
    text-decoration: none;
    
    .outside-button-container-img-agree {
        height: 20px;
        width: 20px;
    }

    .outside-button-container-text-agree {
        margin-left: 15px;
    }
}