.customer-view-main-header-main-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.customer-view-main-header-main-background {
    border-bottom-style: solid;
    border-bottom-width: 0.14em;
    border-bottom-color: rgba(128, 128, 128, 0.137);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    padding: 3px;
    margin: 20px 20px 15px 20px;
    max-width: 1000px;
    width: calc(100% - 40px);
    display: flex;
}

.customer-view-main-header-main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.customer-view-main-header-sections {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 3px;
}

.customer-view-main-header-sections-no-white-space {
    white-space: nowrap;
}

.customer-view-main-header-section-no-over-flow {
    overflow: hidden;
    white-space: nowrap;
    margin-left: 20px;
    margin-right: 20px;
}

.customer-view-main-header-id-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.customer-view-main-header-name-div {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.customer-view-main-header-id-img {
    width: 18px;
    height: 18px;
    line-height: 1.5; 
}

.customer-view-main-status-icons {
    width: 16px;
    height: 16px;
    margin-right: -3px;
}

.customer-view-main-header-id-p {
    font-size: 18px;
    font-weight: bold;
    padding: 3px 8px;
    cursor: pointer;
    border-radius: 15px;

    line-height: 1.5; 
}

.customer-view-main-header-id-p:hover {
    background-color: rgba(128, 128, 128, 0.13);
}

.customer-view-main-header-data-p {
    font-size: 16px;
    padding: 3px 8px;
    border-radius: 15px;
    line-height: 1.5; 
}

.customer-view-main-header-data-p-hover {
    font-size: 16px;
    padding: 3px 8px;
    cursor: pointer;
    border-radius: 15px;
    line-height: 1.5; 
}

.customer-view-main-header-data-p-hover:hover {
    background-color: rgba(128, 128, 128, 0.13);
}

.customer-view-page-main-buttons-cont {
    display: flex;
    justify-content: center;
    align-items: center;
}

.customer-view-page-main-buttons-sect {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    margin-left: 20px;
}

.customer-view-page-main-button-elem {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    background-color: var(--dark-primary);
    color: white;
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    padding: 10px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 8px;
    min-width: 110px;
    max-width: 110px;
    white-space: nowrap;
    margin-left: 5px;
    margin-right: 5px;

    overflow: hidden;

    img {
        width: 18px;
        height: 18px;
    }

    p {
        margin-left: 10px;
        margin-top: -2px;
        font-size: 14px;
    }
}

.customer-view-page-main-button-elem:hover {
    background-color: #3f5266;
}
