.products-detaines-info-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.products-details-page-main-container {
    display: flex;
    width: calc(100% - 60px);
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    flex-wrap: wrap;
}

.products-details-edict-options-btn {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.products-more-details-section-first {
    width: 100%;
}

.products-more-details-section-second {
    width: 100%;
    margin: 5px;
    padding-bottom: 15px;
}

.product-picture-upload-butt {
    display: flex;
    border: none;
    width: 170px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    background-color: rgba(128, 128, 128, 0.13);

    p {
        padding: 5px;
        margin-left: 10px;
    }

    input {
       display: none; 
    }

    img {
        width: 25px;
        padding: 5px;
        margin-right: 10px;
    }
}

.product-picture-upload-butt:hover {
    background-color: rgba(128, 128, 128, 0.308);
}

.product-picture-upload-butt-no-hover {
    display: flex;
    border: none;
    width: 170px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    background-color: rgba(128, 128, 128, 0.13);

    p {
        padding: 5px;
        margin-left: 10px;
    }

    input {
       display: none; 
    }

    img {
        width: 25px;
        padding: 5px;
        margin-right: 10px;
    }
}

.product-picture-upload-butt-no-hover:active {
    background-color: rgba(128, 128, 128, 0.308);
}

.product-picture-upload-butt-no-hover:visited {
    background-color: rgba(128, 128, 128, 0.308);
}

.products-individual-product-yes-img-big-cont {
    display: flex;
    width: 100%;
    height: 260px;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 10px;
}

.pro-ind-prod-yes-img-big-con-div {
    width: 240px; 
    height: 240px;
    cursor: pointer;
}

.pro-ind-prod-yes-img-big-con-div:hover {
    img {
        opacity: 0.8;
    }
}

.products-individual-product-yes-img-big {
    border-radius: 0px;
    background-color: rgba(128, 128, 128, 0.171);
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

// .products-individual-product-no-img-big-cont {
//     display: flex;
//     width: 100%;
//     height: 240px;
//     justify-content: center;
//     align-items: center;
// }

// .products-individual-product-no-img-big {
//     width: 140px;
//     height: 140px;
//     border-radius: 0px;
// }

.products-individual-product-no-img-big-cont {
    display: flex;
    width: 100%;
    height: 260px;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 10px;
}

.pro-ind-prod-no-img-big-con-div {
    width: 240px; 
    height: 240px;
    background-color: rgba(128, 128, 128, 0.171);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.products-individual-product-no-img-big {
    width: 120px;
    height: 120px;
    border-radius: 0px;
}

.customer-detaines-info-out-of-stock-span-cont {
    font-weight: bold;
    background-color: rgba(189, 47, 47, 0.822);
    padding: 0px 8px 2px 8px;
    border-radius: 10px;
    color: white;
    font-size: 14px;
    margin-bottom: -10px;
}

.new-customer-button-add-to-cart {
    padding: 10px 20px;
    height: 38px;
    font-size: 16px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--dark-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 20px;
        height: 20px;
    }
}

.new-customer-button-add-to-cart:hover {
    background-color: #3f5266;
}

.add-to-card-btn-product {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--dark-primary);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
}

.add-to-card-btn-product:hover {
    background-color: #3f5266;
}

.add-to-card-btn-product-no-hover {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--dark-primary);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
}

.add-to-card-btn-product-no-hover:active {
    background-color: #3f5266;
}

.add-to-card-btn-product-no-hover:visited {
    background-color: #3f5266;
}

.remove-from-card-btn-product {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    background-color: rgba(128, 128, 128, 0.13);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
}

.remove-from-card-btn-product:hover {
    background-color: rgba(128, 128, 128, 0.308);
}

.remove-from-card-btn-product-no-hover {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    background-color: rgba(128, 128, 128, 0.13);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
}

.remove-from-card-btn-product-no-hover:active {
    background-color: rgba(128, 128, 128, 0.308);
}

.remove-from-card-btn-product-no-hover:visited {
    background-color: rgba(128, 128, 128, 0.308);
}