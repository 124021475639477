.admin-choose-account-cont {
    display: flex;
    width: calc(100% - 30px);
    margin: 15px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.div-search-input-home {
    width: calc(100% - 30px);
    max-width: 500px;
    margin-top: 20px;
    display: flex;
}

.search-input-home {
    outline: none;
    width: 100%;
    padding-left: 15px;
    border-radius:  100px 0 0 100px;
    margin-top: 20px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    color: black;
}

.search-input-home-dar {
    border: 1px solid rgba(255, 255, 255, 0.075);
    border-right: none;
}

.search-input-home-lig {
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-right: none;
}

.search-input-home-dar:focus {
    border: 1px solid rgb(108, 167, 221);
    border-right: none;
}

.search-input-home-lig:focus {
    border: 1px solid rgba(108, 166, 221, 0.884);
    border-right: none;
}

.search-button-home-dar {
    border: 1px solid rgba(255, 255, 255, 0.075);
    border-right: none;
    border-left: none;
}

.search-button-home-lig {
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-right: none;
    border-left: none;
}

.search-button-home-dar-focus {
    border: 1px solid rgb(108, 167, 221);
    border-right: none;
    border-left: none;
}

.search-button-home-lig-focus {
    border: 1px solid rgba(108, 166, 221, 0.884);
    border-right: none;
    border-left: none;
}

.search-div-delete {
    outline: none;
    margin-top: 20px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;

    button {
        padding: 7px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        border-radius: 100px;
        margin-right: 10px;
    }
    
    .search-div-delete-button-light:hover {
        background-color: var(--light-tertiary-bg);
    }

    .search-div-delete-button-dark:hover {
        background-color: var(--dark-tertiary-bg);
    }

    img {
        width: 16px;
        height: 16px;
    }
}

.search-button-home {
    border-radius:  0 100px 100px 0;
    background-color: var(--dark-primary);
    border: none;
    margin-top: 20px;
    padding: 7px;
    width: 90px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    img {
        width: 20px;
        height: 20px;
    }
}

.search-button-home:hover {
    background-color: #3f5266;
}

.admin-choose-account-card-div {
    width: 100%;
    max-width: 490px;
}

.account-details-selection-container {
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-radius: 10px;
    padding: 10px;
    margin-top: 50px;
}

.account-datails-btn-section {
    display: flex;
    width: 100%;
    justify-items: center;
    justify-content: center;

    button {
        display: flex;
        align-items: center;
        padding: 10px 30px;
        border-radius: 100px;
        margin: 15px;
        margin-top: 35px;
        background-color: var(--dark-primary);
        color: white;
        border: none;
        font-weight: bold;
        cursor: pointer;
        box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    }

    button:hover {
        background-color: #3f5266;
    }
}

.account-datails-img-section {
    width: 18px;
    height: 18px;
    margin-left: 15px;
}

.account-datails-img-exit-section {
    width: 18px;
    height: 18px;
    margin-right: 15px;
}

.admin-choose-empty-card-div {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}

.autho-users-list-empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        color: rgb(148, 148, 148);
    }
}

.autho-users-list-empty-img-div {
    width: 150px;
    height: 150px;
    margin-top: 60px;
}

.autho-users-list-empty-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}