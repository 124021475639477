:root {
    --light-primary: #d3f3ff;
    --light-primary-bg: #fff;
    --light-secundary-bg: #f7f7f7;
    --light-tertiary-bg: #e2e2e2;
    --dark-primary: #627890;
    --dark-primary-bg: #202022;
    --dark-secundary-bg: #2b2b2b;
    --dark-tertiary-bg: #555;
    --focus-primary-bg: rgba(177, 227, 255, 0.452);
    --focus-secundary-bg: rgb(177, 226, 255);
    --alert-red: rgba(255, 0, 0, 0.486);
}

// General backgrounds for light theme
.dark-text-input {
    background: rgba(255, 255, 255, 0.801);
}

.dark-text {
    color: white;
}

.light-text {
    color: black;
}

.lig-pri {
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background-color: var(--light-primary);
    color: black;
}

.lig-pri-bg {
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background-color: var(--light-primary-bg);
    color: black;
}

.lig-sec-bg {
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background-color: var(--light-secundary-bg);
    color: black;
}

.lig-ter-bg {
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background-color: var(--light-tertiary-bg);
    color: black;
}

// General backgrounds for dark theme
.dar-pri {
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background-color: var(--dark-primary);
    color: white;
}

.dar-pri-bg {
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background-color: var(--dark-primary-bg);
    color: white;
}

.dar-sec-bg {
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background-color: var(--dark-secundary-bg);
    color: white;
}

.dar-ter-bg {
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background-color: var(--dark-tertiary-bg);
    color: white;
}

// Border box
.border-box-dark {
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.border-box-light {
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.input-border-box-dark {
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.input-border-box-light {
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.input-border-box-dark:focus {
    border: 1px solid rgb(108, 167, 221);
}

.input-border-box-light:focus {
    border: 1px solid rgba(108, 166, 221, 0.884);
}

.input-border-box-dark-focus {
    border: 1px solid rgb(108, 167, 221);
}

.input-border-box-light-focus {
    border: 1px solid rgba(108, 166, 221, 0.884);
}

// Gradients background
.dar-grad-bg {
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background: #232526;  
    background: -webkit-linear-gradient(to right, #414345, #232526);
    background: linear-gradient(to right, #414345, #232526);
    color: white;
}

.dar-grad-bg-solid {
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background: var(--dark-secundary-bg);  
    color: white;
}

.lig-grad-bg {
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background: #abbaab;
    background: -webkit-linear-gradient(to right, #e2e2e2, #fafafa );  
    background: linear-gradient(to right, #e2e2e2, #fafafa ); 
    color: black;    
}

.lig-grad-bg-solid {
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background: var(--light-secundary-bg);
    color: black;
}

// General box-shadow for lith theme
.lig-box-sha { box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2); }

.lig-box-sha-hover { box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2); }

.lig-box-sha-hover:hover { box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); } 

.lig-box-sha-none-hover { box-shadow: none; }

.lig-box-sha-none-hover:hover { box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); }

.lig-box-sha-keep-hover { box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); }

// General box-shadow for dark theme
.dar-box-sha { box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2); }

.dar-box-sha-hover { box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2); }

.dar-box-sha-hover:hover { box-shadow: 3px 3px 3px 2px rgba(0, 0, 0, 0.2); }

.dar-box-sha-none-hover { box-shadow: none; }

.dar-box-sha-none-hover:hover { box-shadow: 3px 3px 3px 2px rgba(0, 0, 0, 0.2); }

.dar-box-sha-keep-hover { box-shadow: 3px 3px 3px 2px rgba(0, 0, 0, 0.2); }

// To give color to the scroll bar
.lig-scroollbar::-webkit-scrollbar-thumb { 
    background: #3e404731;
    border-radius: 100vw;
}

.lig-scroollbar::-webkit-scrollbar {
    width: 0.4em;
}

.lig-scroollbar::-webkit-scrollbar-track {
    background: #9ba3b81f;
}

.dark-scroollbar::-webkit-scrollbar-thumb { 
    background: #eff1f731;
    border-radius: 100vw;
}

.dark-scroollbar::-webkit-scrollbar {
    width: 0.4em;
}
  
.dark-scroollbar::-webkit-scrollbar-track {
    background: #9ba3b81f;
}

// Box border line
.lig-box-border-line {
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.dar-box-border-line {
    border: 1px solid rgba(255, 255, 255, 0.075);
}