.sk-fading-circle-dark3 {
    margin: 10px auto;
    width: 35px;
    height: 35px;
    position: relative; 
}

.sk-fading-circle-dark3 .sk-circle-dark3 {
    width: 100%;
    height: 100%;
    position: absolute;
    
    left: 0;
    top: 0;
}

.sk-fading-circle-dark3 .sk-circle-dark3:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: rgba(255, 255, 255, 0.356);
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle-dark3 .sk-circle2-dark3 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
}

.sk-fading-circle-dark3 .sk-circle3-dark3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}

.sk-fading-circle-dark3 .sk-circle4-dark3 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sk-fading-circle-dark3 .sk-circle5-dark3 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
}

.sk-fading-circle-dark3 .sk-circle6-dark3 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
}

.sk-fading-circle-dark3 .sk-circle7-dark3 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.sk-fading-circle-dark3 .sk-circle8-dark3 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
}

.sk-fading-circle-dark3 .sk-circle9-dark3 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
}

.sk-fading-circle-dark3 .sk-circle10-dark3 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}

.sk-fading-circle-dark3 .sk-circle11-dark3 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
}

.sk-fading-circle-dark3 .sk-circle12-dark3 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
}

.sk-fading-circle-dark3 .sk-circle2-dark3:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.sk-fading-circle-dark3 .sk-circle3-dark3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.sk-fading-circle-dark3 .sk-circle4-dark3:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.sk-fading-circle-dark3 .sk-circle5-dark3:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.sk-fading-circle-dark3 .sk-circle6-dark3:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

.sk-fading-circle-dark3 .sk-circle7-dark3:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

.sk-fading-circle-dark3 .sk-circle8-dark3:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.sk-fading-circle-dark3 .sk-circle9-dark3:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.sk-fading-circle-dark3 .sk-circle10-dark3:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.sk-fading-circle-dark3 .sk-circle11-dark3:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.sk-fading-circle-dark3 .sk-circle12-dark3:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {

    0%,
    39%,
    100% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }
}

@keyframes sk-circleFadeDelay {

    0%,
    39%,
    100% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }
}