.see-more-results-button-go {
    padding: 8px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--dark-primary);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-left: 10px;
    }

}

.see-more-results-button-go:hover {
    background-color: #3f5266;
}