.change-plan-cre-acc-card {
    padding: 20px;
    border-radius: 10px;
    min-width: 300px;
    max-width: 300px;
    margin-bottom: 20px;
    margin: 20px;

    ul {
        margin-bottom: 10px;
    }

    li {
        padding: 10px;
        list-style: none;
        
    }

}

.change-plan-cre-acc-title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;

    h3 {
        padding: 10px;
    }

    p {
        padding: 5px;
    }
}

.change-plan-cre-acc-icons {
    width: 16px;
    height: 16px;
}

.change-plan-acc-button-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;
    width: 70%;

    color: white;
    background-color: var(--dark-primary);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    
    .change-plan-acc-button-container-img {
        height: 20px;
        width: 20px;
    }

    .change-plan-acc-button-container-text {
        margin-left: 15px;
    }
}

.change-plan-acc-button-container:hover {
    background-color: #3f5266;
}

.change-plan-button-cont {
    display: flex;
    width: 100%;
    margin: 30px 0 30px 0;
    justify-content: center;
}

.change-plan-button-current {
    width: 70%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px;

    
}