.products-details-micro-product-no-img-cont {
    display: flex;
    width: 55px;
    min-width: 55px;
    height: 55px;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: rgba(128, 128, 128, 0.171);
}

.products-details-micro-product-yes-img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.products-details-micro-product-no-img {
    width: 30px;
    height: 30px;
}

.products-details-micro-p-overflow {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.3em;
}