.page-navigator-container {
    margin-top: 20px;
    margin-bottom: 30px;
}

.page-navigator-element {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigator-element-button {
    width: 65px;
    padding: 5px;
    margin: 5px;
    border: none;
    background-color: var(--dark-primary);
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    color: white;
    font-weight: bold;
    border-radius: 10px;
    display: flex;
    cursor: pointer;
    justify-content: center;

    img {
        width: 20px;
    }
}

.navigator-element-page-number {
    padding-left: 5px;
    padding-right: 5px;
}

.navigator-element-button-disable-dark {
    width: 65px;
    padding: 5px;
    margin: 5px;
    border: none;
    background-color: #62789050;
    color: rgba(255, 255, 255, 0.637);
    font-weight: bold;
    border-radius: 10px;
    display: flex;
    justify-content: center;

    img {
        width: 20px;
    }
}

.navigator-element-button-disable-light {
    width: 65px;
    padding: 5px;
    margin: 5px;
    border: none;
    background-color: #728ca794;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    display: flex;
    justify-content: center;

    img {
        width: 20px;
    }
}

.navigator-element-button:hover {
    background-color: #3f5266;
}

.navigator-element-input {
    padding: 5px;
    
    input {
        padding: 8px;
        border: none;
        box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
        width: 50px;
        border-radius:  10px 0 0 10px;
        outline: none;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
    }

    button {
        width: 30px;
        border-radius:  0 10px 10px 0;
        background-color: var(--dark-primary);
        color: white;
        font-weight: bold;
        cursor: pointer;
        padding: 8px;
        border: none;
        box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    }

    button:hover {
        background-color: #3f5266;
    }
}

.navigator-element-minimize-button {
    padding: 0 8px;
}