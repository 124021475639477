.settings-bills {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    min-height: 200px;
    margin: 10px;
    margin-left: 30px;
    margin-right: 25px;
    margin-bottom: 20px;
}

// Container #1
.settings-bills-cont-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    font-size: 18px;

    p {
        margin-top: 15px;
    }
}

.settings-bills-cont-img-div {
    width: 70px;
    height: 70px;
}

.settings-bills-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

// Container #2
.settings-bills-cont-title {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

// Container #3
.settings-bills-cont-butt {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
}

.paypay-butt-div {
    width: 70px;
    height: 20px;
}

.paypay-butt-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.settings-bills-cont-butt-card {
    width: 280px;

    a {
        text-decoration: none;
        cursor: inherit;
    }
}

.settings-bills-butt {
    padding: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: calc(100% - 30px) ;
    max-width: 280px;
    font-weight: bold;
    margin: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    justify-content: center;
    align-items: center;

}

.settings-bills-cont-butt-what-cont {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.settings-bills-butt-card {
    padding: 10px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    width: calc(100% - 30px) ;
    max-width: 280px;
    margin: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    background-color: var(--dark-primary);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);

}

.settings-bills-butt-card:hover {
    background-color: #3f5266;
}

.settings-bills-butt-disable {
    padding: 10px;
    border: none;
    text-decoration: none;
    border-radius: 10px;
    cursor: inherit;
    width: calc(100% - 30px) ;
    max-width: 280px;
    font-weight: bold;
    margin: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    justify-content: center;
    align-items: center;

}

.settings-bills-butt-card-disable {
    padding: 10px;
    border: none;
    text-decoration: none;
    border-radius: 100px;
    color: rgba(255, 255, 255, 0.603);
    background-color: #6278909f;
    cursor: inherit;
    width: calc(100% - 30px);
    max-width: 280px;
    font-weight: bold;
    margin: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    justify-content: center;
    align-items: center;

}

.bills-card-butt-img-credict {
    width: 20px;
    margin-right: 10px;
}

// Container #4
.settings-bills-cont-adv {
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    
    p {
        padding: 10px;
    }
}

.settings-bills-cont-adv-p-div {
    // margin-right: 50px;
    width: 260px;
}

.settings-bills-cont-adv-p {
    padding: 5px;
}

.settings-bills-cont-adv-ul {
    li {
        margin: 20px;
    }
}

.settings-bills-const-amount-hr {
    border-bottom-style: dotted;
    border-bottom-color: rgba(128, 128, 128, 0.486);
    margin: 10px 0px 10px 0px;
}

.settings-bills-const-amount-to-pay {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .settings-bills-const-amount-to-pay-p {
        margin-left: 20px;
    }
}

.settings-bills-const-amount-to-pay-item-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px 10px;
}

.settings-bills-const-amount-to-pay-item {
    display: flex;
    border: 2px solid transparent;
    background-color: rgba(128, 128, 128, 0.055);
    width: calc(100% - 40px);
    max-width: 210px;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    padding: 10px;
    margin: 10px;
    margin-top: 20px;
    cursor: pointer;

    label {
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    input {
        width: 18px;
        height: 18px;
        margin: 3px;
        margin-right: 10px;
        cursor: pointer;
    }
}

.settings-bills-const-amount-to-pay-item-hover {
    background-color: #62789036;
    border: 2px solid #627890a9;
}

.settings-bills-const-amount-to-pay-item:hover {
    background-color: #62789036;
    // border: 2px solid #627890a9;
}