.inventory-table-container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
}

.inventory-table-section-1 {
  height: calc(100vh - 65px);
  border-top-left-radius: 10px;
}

.inventory-table-select-table {
    border-spacing: 0;
    z-index: 0;
}

.inventory-table-select-th {
    padding: 5px;
    background-color: #627890;
    color: white;
    top: 0;
}

.inventory-table-select-td {
    padding: 5px;
    min-height: 30px;

    p {
        padding: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-align: start;
        line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5em;
    }
}

.inventory-table-select-td-sticky-dark {
    background-color: #353535;
}

.inventory-table-select-td-sticky-light {
    background-color: #eeeeee;
}

// hover
.inventory-table-select-tr-dark-hover {
    background-color: var(--dark-secundary-bg);
}

.inventory-table-select-tr-dark-hover:hover {
    background-color: #353535;

    td {
        background-color: #353535;
    }
}

// no hover
.inventory-table-select-tr-dark-no-hover {
    background-color: var(--dark-secundary-bg);
}

.inventory-table-select-tr-dark-no-hover:active {
    background-color: #353535;

    td {
        background-color: #353535;
    }
}

// hover
.inventory-table-select-tr-light-hover {
    background-color: var(--light-secundary-bg);

}

.inventory-table-select-tr-light-hover:hover {
    background-color: #ebebeb;

    td {
        background-color: #ebebeb;
    }
}

// no-hover
.inventory-table-select-tr-light-no-hover {
    background-color: var(--light-secundary-bg);

}

.inventory-table-select-tr-light-no-hover:active {
    background-color: #ebebeb;

    td {
        background-color: #ebebeb;
    }
}

.inventory-table-select-td-sticky {
    left: 0;
    z-index: 2;
}

.inventory-table-select-th-sticky {
    position: sticky;
    left: 0;
    z-index: 1;
}

.inventory-table-select-checkbox {
    min-width: 18px;
    min-height: 18px;
    cursor: pointer;
}