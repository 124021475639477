.page-pricing-options-container {
    width: 100%;
    height: calc(100vh - 65px);
    flex-direction: column;
    display: flex;
    overflow: hidden;  
    overflow-y: auto;
}

.page-pricing-options-item {
    width: 100%;
    height: 100%;
}

.page-pricing-options-header {
    display: flex;
    width: 100%;
    align-items: center;

    img {
        width: 35px;
        margin: 30px 20px; 
        margin-left: 8%;
    }
}

.page-pricing-options-tittle {
    font-weight: bolder;
    font-size: 16px;
}

.page-pricing-cards-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}