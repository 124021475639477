.cust-account-history-notes {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;  
    overflow-y: scroll;
}

.cust-account-history-notes::-webkit-scrollbar {
    width: 0.7em;
}

.cust-account-history-notes::-webkit-scrollbar-thumb {
    background: #9ba3b85d;
    border-radius: 100vw;
}

.cust-account-history-con-buttons {
    display: flex;
    justify-content: center;
    margin-top: -10px;
}

.cust-account-history-spinner-cont {
    margin-top: 60px;
}

.cust-account-history-con-buttons-elem {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    background-color: var(--dark-primary);
    color: white;
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    padding: 10px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 8px;
    white-space: nowrap;
    margin-left: 15px;
    margin-right: 15px;

    img {
        width: 16px;
        height: 16px;
    }

    p {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: -2px;
        font-size: 14px;
    }
}

.cust-account-history-con-buttons-elem:hover {
    background-color: #3f5266;
}

.cust-account-history-notes-item {
    box-shadow: 1px 1px 1px 0.5px rgba(128, 128, 128, 0.226);
    margin: 10px 5px;
    padding: 2px;
    border-radius: 10px;
}

.cust-account-history-notes-element-date-and-delete {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.cust-account-history-notes-element-date {
    padding: 10px;
    font-size: 12px;
    font-weight: bold;
}

.cust-account-history-notes-element-content {
    padding: 5px 10px;
    font-size: 14px;
}

.cust-account-history-notes-element-delete {
    margin-right: 10px;
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;

    img {
        width: 16px;
        height: 16px;
    }
}
  
.cust-account-history-notes-element-delete:hover {
  background-color: rgba(128, 128, 128, 0.342);
}

.cust-account-history-notes-new-cont {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cust-account-history-notes-new-title {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
}

.cust-account-history-notes-new-textarea {
    margin: 8px 5px 10px 5px;
    width: calc(100% - 40px);
    outline: none;
    height: 180px;
    min-height: 110px;
    border-radius: 10px;
    padding: 10px;
    resize: none;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.cust-account-history-notes-new-textarea::placeholder {
    color: rgba(128, 128, 128, 0.61);
}

.new-custmer-textarea-alert {
    border: solid 3px rgba(255, 0, 0, 0.39);
}

.cust-account-history-con-more-notes-con {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
}

.edit-customer-desktop-dark {
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background-color: var(--dark-primary-bg);
    color: white;
}

.edit-customer-desktop-light {
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background-color: var(--light-primary-bg);
    color: black;
}

.cust-account-history-textarea-border-dark {
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.cust-account-history-textarea-border-light {
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.cust-account-history-textarea-border-dark:focus {
    border: 1px solid rgb(108, 167, 221);
}

.cust-account-history-textarea-border-light:focus {
    border: 1px solid rgba(108, 166, 221, 0.884);
}