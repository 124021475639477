.page-app-customers-container {
    width: 100%;
    height: calc(100vh - 65px);
    flex-direction: column;
    display: flex;
    overflow: hidden; 
}

.page-app-customers-container::-webkit-scrollbar {
    width: 0.7em;
}

.page-app-customers-container::-webkit-scrollbar-thumb {
    background: #9ba3b85d;
    border-radius: 100vw;
}

.page-app-customers-item {
    width: 100%;
    height: 100%;
}

.customers-options-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.customers-options-container-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin-bottom: 50px;
}

.customer-page-recently-open-content {
    min-height: 200px;
    overflow: hidden;  
    overflow-y: scroll;
}

.customer-page-recently-open-content-desktopView {
    min-height: 200px;
    overflow: hidden;  
    overflow-y: scroll;
}

.no-customer-yet-main-container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;

    p {
        text-align: center;
        padding: 10px;
        color: gray;
    }
}

.no-customer-yet-main-img {
    width: 80px;
    height: 80px;
    margin: 20px;
}

.customer-header-button-div {
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;

    a {
        display: flex;
        justify-content: center;
        text-decoration: none;
        align-items: center;
        padding: 10px;
        border: none;
        border-radius: 100px;
        cursor: pointer;

        img {
            width: 18px;
            height: 18px;
        }

        p {
            margin-left: 10px;
            font-size: 14px;
        }
    }
}

.customer-header-button-link {
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    background-color: var(--dark-primary);
    color: white;
}

.customer-header-button-link:hover {
    background-color: #3f5266;
}