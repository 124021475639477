.new-product-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 890px;
    margin: 20px;
}

.add-product-service-first-opt-cont {
    display: flex;
    width: 100%;
}

.add-product-service-first-opt-item {
    display: flex;
    width: 50%;
    flex-direction: column;
}

.add-prod-serv-select-container {
    max-width: 282px;
}

.add-prod-serv-select-container-current {
    padding: 10px;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    width: calc(100% - 20px);;
    max-width: 280px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        white-space: nowrap;
    }
}

.add-prod-serv-select-container-current-arrow-img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.add-prod-serv-select-container-options {
    padding: 8px;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    max-width: 265px;
    position: fixed;
}

.add-prod-serv-select-options {
    padding: 10px 5px;
    border-radius: 10px;
    margin: 2px;
    cursor: pointer;
}

.add-prod-serv-select-options-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 200ms ease;
}

.add-prod-serv-select-options-inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 200ms ease;
}

.add-prod-serv-select-container-options-dark {
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.add-prod-serv-select-container-options-light {
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.add-prod-serv-select-options-dark:hover {
    background-color: var(--dark-secundary-bg);
}

.add-prod-serv-select-options-light:hover {
    background-color: var(--light-secundary-bg);
}

.products-detaines-main-status-icons {
    width: 12px;
    height: 12px;
    margin-right: 3px;
}

.new-producto-section-info-con {
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    padding: 10px 20px 22px 20px;
    margin-top: 20px ;
    border-radius: 10px;
    max-width: 400px;
}

.new-product-sub-title-li {
    font-weight: bolder;
}

.new-product-first-section-for-form-cont {
    display: flex;
    width: 100%;
    justify-content: center;
}

.new-product-sections-page-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.new-product-bottons-page-container {
    display: flex;
    justify-content: center;
}
