.products-individual-product-card {    
    display: flex;
    flex-direction: column;
    min-width: 140px;
    max-width: 140px;
    margin: 10px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    text-decoration: none;
    max-height: 260px;
    overflow-x: hidden;
    cursor: pointer;
}

// Hover
.products-individual-product-focus-card-div-light-HOVER {
    color: black;
    background-color: #62789036;
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.products-individual-product-focus-card-div-light-HOVER:hover {
    background-color: var(--light-tertiary-bg);
}

// No Hover
.products-individual-product-focus-card-div-light-NOHOVER {
    color: black;
    background-color: #62789036;
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.products-individual-product-focus-card-div-light-NOHOVER:active {
    background-color: var(--light-tertiary-bg);
}

// Hover
.products-individual-product-focus-card-div-dark-HOVER {
    color: white;
    background-color: #62789036;
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.products-individual-product-focus-card-div-dark-HOVER:hover {
    background-color: var(--dark-tertiary-bg);
}

// No Hover
.products-individual-product-focus-card-div-dark-NOHOVER {
    color: white;
    background-color: #62789036;
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.products-individual-product-focus-card-div-dark-NOHOVER:active {
    background-color: var(--dark-tertiary-bg);
}

// Hover
.products-individual-product-card-div-light-HOVER {
    color: black;
    background-color: var(--light-secundary-bg);
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.products-individual-product-card-div-light-HOVER:hover {
    background-color: var(--light-tertiary-bg);
}

// No Hover
.products-individual-product-card-div-light-NOHOVER {
    color: black;
    background-color: var(--light-secundary-bg);
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.products-individual-product-card-div-light-NOHOVER:active {
    background-color: var(--light-tertiary-bg);
}

// Hover
.products-individual-product-card-div-dark-HOVER {
    color: white;
    background-color: var(--dark-secundary-bg);
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.products-individual-product-card-div-dark-HOVER:hover {
    background-color: var(--dark-tertiary-bg);
}

// No Hover
.products-individual-product-card-div-dark-NOHOVER {
    color: white;
    background-color: var(--dark-secundary-bg);
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.products-individual-product-card-div-dark-NOHOVER:active {
    background-color: var(--dark-tertiary-bg);
}

.products-individual-product-id {
    padding: 4px;
    font-size: 12px;
    font-weight: bold;
}

.products-individual-product-yes-img-cont {
    display: flex;
    width: 100%;
    height: 140px;
    justify-content: center;
    align-items: center;
}

.products-individual-product-no-img-cont {
    display: flex;
    width: 100%;
    height: 140px;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.171);
}

.products-individual-product-yes-img {
    // width: 140px;
    // height: 140px;
    border-radius: 0px;
    background-color: rgba(128, 128, 128, 0.171);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.products-individual-product-no-img {
    width: 70px;
    height: 70px;
    padding: 10px 0;
}

.products-individual-product-name-div {
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
}

.products-individual-product-name {
    padding: 4px;
    font-weight: bold;
}

.products-individual-product-price {
    padding: 4px;
    padding-bottom: 8px;
    font-weight: bold;
    white-space: nowrap;
}

.prod-card-info-out-of-stock-span-cont {
    font-weight: bold;
    background-color: rgba(189, 47, 47, 0.822);
    padding: 0px 8px 2px 8px;
    color: white;
    font-size: 14px;
    text-align: center;
    margin-top: -20px;
}

.remove-from-card-cart-btn-product {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    background-color: rgba(187, 187, 187);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
}

.remove-from-card-cart-btn-product:hover {
    background-color: rgb(224, 224, 224);
}

.remove-from-card-cart-btn-product-no-hover {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    background-color: rgba(187, 187, 187);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
}

.remove-from-card-cart-btn-product-no-hover:active {
    background-color: rgb(204, 204, 204);
}

.remove-from-card-cart-btn-product-no-hover:visited {
    background-color: rgb(204, 204, 204);
}