.get-quote-component-dashed-line {
    border: none;
    border-top: 0.2px dashed black;
    color: black;
    background-color: transparent;
    height: 0.2px;
    width: 100%;
}

.get-quote-component-font {
    font-family: 'Arial', sans-serif;
}