.settings-container-paragraph {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
        padding: 10px;
        text-align: justify;
        margin-right: 30px;
    }
}