.product-mini-view-card-div {
    cursor: pointer;
    text-decoration: none;
    border-radius: 10px;
    width: calc(100% - 30px);
    // max-width: 600px;
    margin: 15px 15px 8px 10px;
    display: flex;
    font-size: 14px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    border: none;
    height: 75px;
    max-height: 75px;
    min-height: 75px;
}

// Hover
.product-mini-view-focus-card-div-light-HOVER {
    color: black;
    background-color: #62789036;
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.product-mini-view-focus-card-div-light-HOVER:hover {
    background-color: var(--light-tertiary-bg);
}

// No Hover
.product-mini-view-focus-card-div-light-NOHOVER {
    color: black;
    background-color: #62789036;
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.product-mini-view-focus-card-div-light-NOHOVER:active {
    background-color: var(--light-tertiary-bg);
}

// Hover
.product-mini-view-focus-card-div-dark-HOVER {
    color: white;
    background-color: #62789036;
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.product-mini-view-focus-card-div-dark-HOVER:hover {
    background-color: var(--dark-tertiary-bg);
}

// No Hover
.product-mini-view-focus-card-div-dark-NOHOVER {
    color: white;
    background-color: #62789036;
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.product-mini-view-focus-card-div-dark-NOHOVER:active {
    background-color: var(--dark-tertiary-bg);
}

// Hover
.product-mini-view-card-div-light-HOVER {
    color: black;
    background-color: var(--light-secundary-bg);
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.product-mini-view-card-div-light-HOVER:hover {
    background-color: var(--light-tertiary-bg);
}

// No Hover
.product-mini-view-card-div-light-NOHOVER {
    color: black;
    background-color: var(--light-secundary-bg);
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.product-mini-view-card-div-light-NOHOVER:active {
    background-color: var(--light-tertiary-bg);
}

// Hover
.product-mini-view-card-div-dark-HOVER {
    color: white;
    background-color: var(--dark-secundary-bg);
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.product-mini-view-card-div-dark-HOVER:hover {
    background-color: var(--dark-tertiary-bg);
}

// No Hover
.product-mini-view-card-div-dark-NOHOVER {
    color: white;
    background-color: var(--dark-secundary-bg);
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.product-mini-view-card-div-dark-NOHOVER:active {
    background-color: var(--dark-tertiary-bg);
}

.products-mini-view-product-yes-img-cont {
    display: flex;
    width: 75px;
    height: 75px;
    justify-content: center;
    align-items: center;
}

.products-mini-view-product-no-img-cont {
    display: flex;
    width: 75px;
    min-width: 75px;
    height: 75px;
    min-height: 75px;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.171);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.products-mini-view-product-yes-img {
    border-radius: 0px;
    background-color: rgba(128, 128, 128, 0.171);
    width: 75px;
    height: 75px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    object-fit: cover;
}

.products-mini-view-product-no-img {
    width: 45px;
    height: 45px;
}

.products-mini-view-name-overflow {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Número de líneas que deseas mostrar */
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    // height: calc(1.4em * 2); /* Ajusta la altura según el número de líneas */
    line-height: 1.5em; /* Ajusta según la altura de la línea de tu fuente */
}

.products-mini-view-texto-overflow {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Número de líneas que deseas mostrar */
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    height: calc(1.4em * 3); /* Ajusta la altura según el número de líneas */
    line-height: 1.5em; /* Ajusta según la altura de la línea de tu fuente */
}

.products-mini-view-extra-overflow {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
}