.settings-auth-user-cont {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    min-height: 200px;
    margin: 10px;
    margin-left: 30px;
    margin-right: 25px;
    margin-bottom: 40px;
}

.settings-auth-user-hr {
    background-color: #6d6d6d8c;
    height: 1px;
    border: none;
}

.settings-auth-user-name {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.settings-auth-user-name-img {
    width: 100px;
    height: 100px;
    margin: 10px;
    margin-bottom: 15px;
    border-radius: 100px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100px;
    }
}

.settings-auth-user-name-error-img {
    width: 100px; 
    height: 100px;
    background-color: rgba(128, 128, 128, 0.171);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    margin-bottom: 15px;

    img {
        width: 60px;
        height: 60px;
        border-radius: 0px;
    }
}

.settings-auth-user-data {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.settings-auth-user-contact-cont {
    display: flex;
    width: calc(100% - 20px);
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 10px;
    margin-top: 20px;
}

.settings-auth-user-contact-icon {
    width: 20px;
    height: 20px;
    padding: 5px 10px 5px 5px;
}

.settings-auth-user-contact-p {
    display: flex;
    padding: 5px;
    align-items: center;
}

.settings-auth-user-contact-input-email {
    padding: 5px;
    border-radius: 10px;
    width: 240px;
    box-shadow: 0px 0px 0px 1.5px rgba(0, 0, 0, 0.2);
}

.settings-auth-user-contact-input-phone {
    padding: 10px;
    font-size: 15px;
    border-radius: 10px;
    width: 140px;
    outline: none;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
}

.settings-auth-user-edict-name-status-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;

    div {
       padding: 3px;
       display: flex;
       align-items: center;
    }
}

.settings-auth-user-view-name-status-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.settings-auth-user-contact-input-name {
    padding: 10px;
    border-radius: 10px;
    width: 190px;
    margin-left: 10px;
    outline: none;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
}

.settings-auth-user-contact-input-status {
    padding: 10px;
    border-radius: 10px;
    width: 180px;
    margin-left: 10px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    outline: none;
}

.settings-auth-user-access {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    margin: 20px -10px 10px -10px;
}

.settings-auth-user-access-p {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    label {
        padding: 4px 10px 4px 15px;
    }
}

.settings-auth-user-access-p-span {
    font-size: 14px;
}

.settings-auth-user-access-input {
    min-width: 20px;
    min-height: 20px;
    cursor: pointer;
}

.settings-auth-user-access-cont {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px;
    margin-top: 20px;
}

.settings-auth-user-access-cont-first {
    
    width: calc(100% + 20px);
    max-width: 550px;

    p {
        padding: 5px;
        margin-bottom: 5px;
    }
}

.settings-auth-user-name-status-active {
    font-size: 14px;
    border: 1px solid green;
    background-color: rgba(0, 128, 0, 0.075);
    padding: 2px 8px 4px 8px;
    border-radius: 15px;
    margin: 5px;
}

.settings-auth-user-name-status-blocked {
    font-size: 14px;
    border: 1px solid rgb(255, 174, 0);
    background-color: rgba(255, 174, 0, 0.075);
    padding: 2px 8px 4px 8px;
    border-radius: 15px;
    margin: 5px;
}

.settings-auth-user-status-icon {
    width: 18px;
    height: 18px;
    padding: 0 5px 0 5px;
}

.settings-auth-user-access-icon {
    width: 23px;
    height: 23px;
    margin-right: 10px;
}

.settings-auth-user-bottons {
    width: calc(100% - 20px);
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .settings-auth-user-bottons-remove {
        padding: 10px;
        border: none;
        border-radius: 100px;
        cursor: pointer;
        width: 100px;
        margin-right: 10px;
        background: rgba(185, 95, 95, 0.932);
        color: white;
        font-weight: bold;
    }

    .settings-auth-user-bottons-edict {
        padding: 10px;
        border: none;
        border-radius: 100px;
        cursor: pointer;
        width: 120px;
        margin-left: 10px;
        font-weight: bold;
    }
}

.settings-auth-user-bottons-button-disable {
    padding: 10px;
    border: none;
    border-radius: 100px;
    cursor: inherit;
    width: 120px;
    margin-left: 10px;
    font-weight: bold;
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background-color: #62789079;
    color: rgba(255, 255, 255, 0.550);
}

.auth-user-view-profile-yes-img-big-cont {
    display: flex;
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;;
    justify-content: center;
    align-items: center;
    margin: 10px;
    margin-bottom: 15px;
}

.aut-use-view-pro-yes-img-big-con-div {
    min-width: 100px;
    max-width: 100px;
    min-height: 100px;
    max-height: 100px;
}

.edict-auth-user-prof-button-hover {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--dark-primary);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }

}

.edict-auth-user-prof-button-hover:hover {
    background-color: #3f5266;
}

.edict-auth-user-prof-button-no-hover {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--dark-primary);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }

}

.edict-auth-user-prof-button-no-hover:active {
    background-color: #3f5266;
}