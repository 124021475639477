.settings-security {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 40px 25px 20px 30px;

    .settings-security-first-section-title-p {
        padding: 20px;
    }
}

.settings-security-first-section-con {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.settings-security-second-section {
    p {
        padding: 5px;
    }
}

.settings-security-second-section-link-dark {
    color: #00a3ee;
}

.settings-security-second-section-link-dark:hover {
    color: rgb(255, 145, 145);
}

.settings-security-second-section-link-light {
    color: #0000ee;
}

.settings-security-second-section-link-light:hover {
    color: red;
}