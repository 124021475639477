.settings-edict-acc-container {
    border-radius: 8px;
    display: flex;
    padding: 20px;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 25px;
    margin-bottom: 40px;
    justify-content: space-around;
}

.settings-edict-acc-container-first {
    width: 100%;
    min-width: 225px;
    min-height: 210px;
    display: flex;
    flex-direction: column;
}

.settings-edict-acc-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.settings-edict-acc-div-text {
    display: flex;
    flex-direction: column;
}

.settings-edict-acc-div-text-div {
    display: flex;
    flex-wrap: wrap;
}

.settings-edict-acc-div-label {
    padding: 10px 0px 10px 10px;
    width: 171px;
}

.settings-edict-acc-input-text {
    padding: 10px;
    border-radius: 10px;
    margin-left: 10px;
    width: 220px;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
}

.settings-edict-acc-input-text-read-only {
    width: 260px;
    margin-left: 10px;
}

.settings-edict-acc-div-buttons {
    max-width: 400px;
    display: flex;
    width: 100%;
    height: 70px;
    justify-content: space-around;
    align-items: center;
}

.settings-edict-acc-container-button-delete {
    padding: 10px;
    border-radius: 100px;
    border: none;
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    width: 125px;
    background: rgba(185, 95, 95, 0.932);
    color: white;
}

.settings-edict-acc-container-button-delete-disable {
    padding: 10px;
    border-radius: 100px;
    border: none;
    width: 100%;
    cursor: inherit;
    font-weight: bold;
    width: 125px;
    background: rgba(185, 95, 95, 0.500);
    color: rgba(255, 255, 255, 0.750);
}

.settings-edict-acc-cont-div-buttons {
    display: flex;
    width: 100%;
    margin-top: 30px;
    justify-content: center;
}

.settings-edict-acc-container-button {
    padding: 10px;
    border-radius: 100px;
    border: none;
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    width: 100px;
}

.settings-edict-acc-container-button-disable {
    padding: 10px;
    border-radius: 100px;
    border: none;
    width: 100%;
    cursor: inherit;
    font-weight: bold;
    width: 100px;
    transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
    background-color: #62789079;
    color: rgba(255, 255, 255, 0.550);
}