.close-account-form-page-container {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 60px;
    align-items: center;
}

.close-account-form-page-item {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.warning-del-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0 10px 0;

    .warning-del-img {
        width: 90px;
        height: 90px;
    }
}

.warning-del-container-link {
    margin: 0 0 20px 30px;
    width: 280px;
    text-decoration: underline;
    color: rgb(0, 185, 185);
    cursor: pointer;
}

.close-account-form-page-description {
    margin: 10px;
    margin-left: 13px;
}

.close-account-form-page-input {
    margin: 2px 10px 10px 10px;
    padding: 10px;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    border: solid 3px transparent;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
}

.close-account-form-page-alert {
    border: solid 3px var(--alert-red);
}

.close-account-form-page-button {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px;
    background-color: rgba(247, 37, 37, 0.89);
    cursor: pointer;
    margin: 10px;
    text-decoration: none;
    color: white;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    
    .close-account-form-page-img {
        height: 20px;
        width: 20px;
    }

    .close-account-form-page-text {
        margin-left: 15px;
    }
}

.close-account-form-page-button:hover {
    background-color: rgba(134, 26, 26, 0.884);
}

.close-account-enable-page-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.close-account-enable-page-p {
    padding: 10px;
    text-align: justify;
    margin-bottom: 30px;
}

.close-account-enable-page-button {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    width: 300px;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;
    color: white;

    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    background-color: var(--dark-primary);
    color: white;
    
    .close-account-form-page-img {
        height: 20px;
        width: 20px;
    }

    .close-account-form-page-text {
        margin-left: 15px;
    }
}

.close-account-enable-page-button:hover {
    background-color: #3f5266;
}