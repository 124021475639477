.privacy-policies-container {
    margin-left: 30px;
    margin-right: 25px;
    margin-bottom: 50px;
}

.privacy-policies-container-p-justify {
    text-align: justify;
}

.privacy-policies-container-p-margin {
    margin-left: 20px;
}