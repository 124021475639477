.shopping-cart-search-for-btn {
    padding: 8px;
    margin: 0px 10px;
    border-radius: 20px;
    border: none;
    display: flex;
    width: 150px;
    justify-content: center;
    align-items: center;    
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    p {
        margin-right: 15px;
    }

    img {
        width: 18px;
        height: 18px;
    }
}

.shopping-cart-search-for-btn-dark-hover  {
    background-color: var(--dark-tertiary-bg);
    color: white;
}

.shopping-cart-search-for-btn-light-hover  {
    background-color: var(--light-tertiary-bg);
    color: black;
}

.shopping-cart-search-for-btn-dark-no-hover {
    background-color: var(--dark-tertiary-bg);
    color: white;
}

.shopping-cart-search-for-btn-light-no-hover {
    background-color: var(--light-tertiary-bg);
    color: black;
}

.shopping-cart-search-for-btn-dark-hover:hover  {
    background-color: #55555583;
}

.shopping-cart-search-for-btn-light-hover:hover  {
    background-color: #cccccc;
}

.shopping-cart-search-for-btn-dark-no-hover:active {
    background-color: #55555583;
}

.shopping-cart-search-for-btn-light-no-hover:active {
    background-color: #cccccc;
}

.shopp-search-for-cont {
    margin: 20px 0px 0px 20px;
    min-width: 220px;
    height: 40px;
}

.shopp-search-for-btn-left {
    padding: 8px;
    border: none;
    min-width: 110px;
    white-space: nowrap;
    height: 40px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
}

.shopp-search-for-btn-right {
    padding: 8px;
    border: none;
    min-width: 110px;
    white-space: nowrap;
    height: 40px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
}

.shopp-search-for-btn-active-dark {
    cursor: pointer;
    color: white;
    background-color: #62789048;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 2.5px solid #627890;
    border-left: 1.5px solid #627890;
    border-right: 1.5px solid #627890;
    font-weight: bold;
}

.shopp-search-for-btn-active-light {
    cursor: pointer;
    color: black;
    background-color: #62789027;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 2.5px solid #627890a2;
    border-left: 1.5px solid #627890a2;
    border-right: 1.5px solid #627890a2;
    font-weight: bold;
}

.shopp-search-for-btn-inactive-light {
    cursor: pointer;
    color: black;
    background-color: var(--light-secundary-bg);
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 2.5px solid transparent;
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
}

.shopp-search-for-btn-inactive-dark {
    cursor: pointer;
    color: white;
    background-color: var(--dark-secundary-bg);
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 2.5px solid transparent;
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
}

.shopp-search-for-btn-inactive-light:hover {
    background-color: #62789027;
}

.shopp-search-for-btn-inactive-dark:hover {
    background-color: #62789048;
}

// Customers
.shoppo-cart-cust-card-div {
    border-radius: 10px;
    margin: 0px 15px 8px 10px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    box-shadow: 0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2);
    border: none;
    min-height: 40px;
}

// Normal
.shoppo-cart-cust-card-div-light {
    color: black;
    background-color: var(--light-secundary-bg);
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.shoppo-cart-cust-card-div-dark {
    color: white;
    background-color: var(--dark-secundary-bg);
    border: 1px solid rgba(255, 255, 255, 0.075);
}

// Hover
.shoppo-cart-cust-card-div-light-HOVER {
    cursor: pointer;
    color: black;
    background-color: var(--light-secundary-bg);
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.shoppo-cart-cust-card-div-light-HOVER:hover {
    background-color: var(--light-tertiary-bg);
}

// No Hover
.shoppo-cart-cust-card-div-light-NOHOVER {
    cursor: pointer;
    color: black;
    background-color: var(--light-secundary-bg);
    border: 1px solid rgba(0, 0, 0, 0.075);
}

.shoppo-cart-cust-card-div-light-NOHOVER:active {
    background-color: var(--light-tertiary-bg);
}

// Hover
.shoppo-cart-cust-card-div-dark-HOVER {
    cursor: pointer;
    color: white;
    background-color: var(--dark-secundary-bg);
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.shoppo-cart-cust-card-div-dark-HOVER:hover {
    background-color: var(--dark-tertiary-bg);
}

// No Hover
.shoppo-cart-cust-card-div-dark-NOHOVER {
    cursor: pointer;
    color: white;
    background-color: var(--dark-secundary-bg);
    border: 1px solid rgba(255, 255, 255, 0.075);
}

.shoppo-cart-cust-card-div-dark-NOHOVER:active {
    background-color: var(--dark-tertiary-bg);
}

.shopp-prod-pictu-hover:hover {
    opacity: 0.8;
}