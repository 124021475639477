.do-not-verify-email-button {
    border-radius: 100%;
    border: none;
    cursor: pointer;
    width: 40px;
    margin-right: 10px;
    margin-top: 5px;

    img {
        width: 16px;
        height: 16px;
        padding: 10px;
    }

}