.section-forbidden-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 200px);
    flex-direction: column;
}

.section-forbidden-page-name {
    display: flex;
    align-items: center;
}

.section-forbidden-title {
    font-size: 18px;
    font-weight: bold;
}

.section-forbidden-img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.section-forbidden-text {
    margin: 20px;
    font-size: 16px;
}