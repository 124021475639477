.autho-users-list-container {
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    margin: 10px;
    margin-left: 30px;
    margin-right: 25px;
    margin-bottom: 40px;
}

.autho-users-list-header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.autho-users-list-select {
    padding: 5px;
    border-radius: 5px;
}

.autho-users-list-tittle {
    margin: 20px 30px 20px 0px;
}

.autho-users-list-search-box {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 15px;
}

.autho-users-list-filter {
    padding: 8px;
    border-radius: 10px 0px 0px 10px;
    border: none;
    font-size: 16px;
    border: solid 3px transparent;
    box-shadow: 0px 0px 0px 1.5px rgba(0, 0, 0, 0.2);
    width: 100%;
    margin-right: 2px;
}

.autho-users-list-searh-button {
    background: var(--dark-primary);
    cursor: pointer;
    border-radius: 0px 10px 10px 0px;
    border: none;
    box-shadow: 0px 0px 0px 1.5px rgba(0, 0, 0, 0.2);
    width: 50px;
    height: 40.5px;
}

.autho-users-list-searh-img {
    width: 25px;
    height: 25px;
}

.autho-users-list-empty-spinner {
    width: 100%;
    min-height: 190px;
}

.autho-users-list-empty {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 240px;

    div {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.autho-users-list-empty-img {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
}

.autho-users-list-users {
    display: flex;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    flex-direction: column;
    //min-height: 240px;
}

.autho-users-list-nav {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 20px;

    div {
        display: flex;
    }
}

.autho-users-list-nav-previous {
    background: var(--dark-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 25px;
    border-radius: 5px;
    border: none;
    margin-right: 10px;
    cursor: pointer;

    img {
        width: 16px;
        height: 16px;
    }
}

.autho-users-list-nav-previous:hover {
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.autho-users-list-nav-next {
    background: var(--dark-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 25px;
    border-radius: 5px;
    border: none;
    margin-left: 10px;
    cursor: pointer;

    img {
        width: 16px;
        height: 16px;
    }
}

.autho-users-list-nav-next:hover {
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}