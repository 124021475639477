.page-choose-account-container {
    width: 100%;
    flex-direction: column;
    display: flex;
    overflow: hidden;
    overflow-y: auto;
}

.page-choose-account-item {
    width: 100%;
    height: 100%;
}

.page-choose-account-tittle {
    margin: 40px;
}

.page-choose-account-options {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.page-choose-account-option {
    max-width: 500px;
    margin: 15px;
    cursor: pointer;
    border-radius: 10px;
}

.page-choose-account-option-unselected {
    width: calc(100% + 4px - 35px);
    margin-left: 20px;
    margin-right: 15px;
}

.page-choose-account-option-selected {
    width: calc(100% - 35px);
    margin-left: 20px;
    margin-right: 15px;
    border-style: solid;
    border-width: 0.15em;
    border-color: var(--focus-secundary-bg);
}

.page-item-account-link {
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding-left: 15px;

    .page-choose-account-category-p {
        font-size: 15px;
        display: flex;
    }

    .page-choose-account-link-img {
        width: 40px; 
        margin: 10px;
    }

    p {
        padding: 3px;
    }
}

.page-choose-account-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 15px;

    .page-choose-account-link-settings {
        width: 20px;
    }

    .page-choose-account-link-img {
        width: 50px;
    }

    img {
        width: 45px;
        margin: 10px;
    }

    p {
        padding: 10px;
    }
}

.page-choose-account-link-2 {
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    // justify-content: center;
    margin-bottom: 5px;
}

.page-choose-account-category-alert {
    display: flex;
    align-items: center;

    img {
        width: 18px;
        height: 18px;
        padding: 0;
        margin-right: 8px;
        margin-top: 0px;
    }
}

.page-choose-account-category-name {
    margin-bottom: 10px;
}

.page-choose-account-open-img {
    width: 23px;
    height: 23px;
    margin-right: 18px;
}

.page-choose-account-opt-dark {
    background-color: #2b2b2b; 
    color: white;
}

.page-choose-account-opt-light {
    background-color: #f7f7f7;
    color: black;
}