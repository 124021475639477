.dark-theme {
  background-color: var(--dark-primary-bg);
  transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
}

.light-theme {
  background-color: var(--light-primary-bg);
  transition: background-color 0.5s cubic-bezier(0.05,0,0,1);
}

.theme-temp {
  background-color: #9BABB8;
}

.App {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
}

.app-container {
  width: 100%;
}

img {
  pointer-events: none;
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}