.lock-screen-alert {
    width: 100%;
    min-height: 100vh;
    background:rgba(0,0,0,0.3);
    position: absolute;
    z-index: 9;
}

.alert-genaral-card {
    background: white;
    color: black;
    width: 250px;
    z-index: 10;
    position: absolute;
    border-radius: 8px;
    top: 15%;
    left: calc(50% - 146px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    max-height: calc(85vh - 100px);
    overflow: hidden;  
    overflow-y: scroll;
}

.alert-genaral-card.active {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
    transition: 200ms ease;
}

.alert-genaral-card.inactive {
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0.5);
    transition: 200ms ease;
}

.alert-genaral-card::-webkit-scrollbar {
    width: 0.4em;
}

.alert-genaral-card::-webkit-scrollbar-thumb {
    background: #42424383;
    border-radius: 100vw;
}

.alert-general-despcrition {

    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-height: 100%;
    text-align: center;

    h4 {
        margin-top: 20px;
        margin: 10px;
    }

    p {
        font-size: 16px;
        margin: 10px;
    }
}

.alert-general-card-img {
    width: 70px;
    height: 70px;
    margin: 20px;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.alert-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 60px;
    min-height: 60px;
    padding-top: 15px;
}

.alert-button-iten {
    width: 90px;
    padding: 12px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    background-color: #627890;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
}

.alert-button-iten:hover {
    background-color: #3f5266;
}

.alert-button-iten-yes {
    width: 90px;
    padding: 12px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    background-color: #627890;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
}

.alert-button-iten-yes:hover {
    background-color: #3f5266;
}

.alert-button-iten-cancel {
    width: 90px;
    padding: 12px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, 0.829);

    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);

    background-color: rgba(128, 128, 128, 0.219);
    color: black;

    display: flex;
    justify-content: center;
    align-items: center;

}

.alert-button-iten-cancel:hover {
    background-color: rgba(128, 128, 128, 0.404);
}
