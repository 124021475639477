.outside-nav-container {
    position: fixed;
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.outside-nav-container-line {
    border-bottom-style: solid;
    border-bottom-width: 0.14em;
    border-bottom-color: rgba(128, 128, 128, 0.137);
}

.outside-nav-logo {
    display: flex;
    flex-direction: column;
    margin: 10px;
    align-items: center;
    justify-content: center;
}

.outside-nav-logo-img {
    margin-left: 5px;
    width: 40px;
    height: 40px;
}

.outside-nav-logo-h3 {
    font-size: 16px;
}

.outside-nav-descrip {
    display: flex;
    flex-direction: column;
    margin: 5px;
    justify-content: center;
    padding: 5px;
}

.outside-nav-descrip-a {
    text-decoration: none;
}

.outside-nav-settings {
    display: flex;
    width: 50px;
    height: 60px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.outside-nav-settings-button {
    cursor: pointer;
    display: flex;
    background-color: transparent;
    border-radius: 20px;
    padding: 10px;
    width: 40px;
    margin-right: 25px;
    border: none;
}

.outside-nav-settings-icon-open {
    width: 20px;
}

.outside-nav-settings-icon-close {
    width: 20px;
}

.pointing-Up-settings {
    content: '';
    position: absolute;
    top: 60px;
    right:28px;
    height: 20px;
    width: 20px;
    background: var(--light-secundary-bg);
    z-index: 2;
}

.pointing-Up-settings.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transform: rotate(45deg);
    transition: 200ms ease;
}

.pointing-Up-settings.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 200ms ease;
}

.dropdown-settings {
    position: absolute;
    top:  65px;
    right: 20px;
    background-color: var(--light-secundary-bg);
    border-radius: 8px;
    padding: 5px 0px 0 5px;
    width: 240px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    max-height: calc(100vh - 100px);
    overflow: hidden;  
    overflow-y: scroll;
}

.pointing-Up-settings.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transform: rotate(45deg);
    transition: 200ms ease;
}

.pointing-Up-settings.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 200ms ease;
}

.dropdown-settings-dark::before {
    background-color: var(--dark-secundary-bg);
}

.dropdown-settings.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 200ms ease;
}

.dropdown-settings.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 200ms ease;
}

.dropdown-settings::-webkit-scrollbar {
    width: 0.4em;
}

.dropdown-settings::-webkit-scrollbar-thumb {
    background: #9ba3b81f;
    border-radius: 100vw;
}

.dropdown-settings-dark {
    background-color: var(--dark-secundary-bg);
    color: white;
}

.dropdown-settings-light {
    color: black;
}