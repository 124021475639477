.labelOptionContainer {
    padding: 2px;
    cursor: pointer;
    border-radius: 10px;
}

.labelOptionContainer:hover {
    background-color: rgba(173, 173, 173, 0.288);
    padding: 2px;
}

.labelOptionContainer-no-hover {
    padding: 2px;
    cursor: pointer;
    border-radius: 10px;
}

.labelOptionContainer-no-hover:active {
    background-color: rgba(173, 173, 173, 0.288);
    padding: 2px;
}

.product-label-btn {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--dark-primary);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }

}

.product-label-btn:hover {
    background-color: #3f5266;
}

.product-label-btn-no-hover {
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: none;
    color: white;
    background-color: var(--dark-primary);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
}

.product-label-btn-no-hover:active {
    background-color: #3f5266;
}