.admin-details-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 10px;
}

.user-picture-displayName {
    display: flex;
    justify-content: center;
    align-items: center;
}

.autho-users-empty-img-div {
    width: 65px;
    height: 65px;
}

.autho-user-empty-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
}

.autho-user-display-name {
    margin-left: 15px;
    
    p {
        padding: 2px 10px;
    }
}

.autho-user-display-name-title {
    font-size: 12px;
}

.user-phone-email {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    
    span {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        padding: 5px;
        font-size: 16px;
        border-radius: 10px;
    
        img {
            width: 16px;
            height: 16px;
            margin-left: 5px;
        }
    }
    
    span:hover {
        background-color: rgba(128, 128, 128, 0.158);
    }
}

