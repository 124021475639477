// ************************** Scroll Element
.customer-details-page-main-container {
    display: flex;
    width: 100%;
    justify-content: center;
    height: calc(100vh - 65px);
    overflow: hidden;  
    overflow-y: auto;
}

.more-details-spinner-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}