.pricing-cre-acc-card {
    padding: 20px;
    border-radius: 10px;
    min-width: 300px;
    max-width: 300px;
    margin-bottom: 20px;
    margin: 20px;

    ul {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    li {
        padding: 10px;
        list-style: none;
        
    }
}

.pricing-cre-account-title {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;

    h3 {
        padding: 10px;
    }

    p {
        padding: 5px;
    }
}

.pricing-cre-acc-free-trial {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 10px 0px 10px;

    img {
        width: 30px;
        margin-right: 10px;
    }
}

.pricing-cre-acc-icons {
    width: 16px;
    height: 16px;
}

.outside-cre-acc-button-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    margin: 10px;
    text-decoration: none;

    color: white;
    background-color: var(--dark-primary);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    
    .outside-cre-acc-button-container-img {
        height: 20px;
        width: 20px;
    }

    .outside-cre-acc-button-container-text {
        margin-left: 15px;
    }
}

.outside-cre-acc-button-container:hover {
    background-color: #3f5266;
}

.pricing-cre-acc-most-popular-label {
    text-align: center;
    margin-top: 15px;
    background-color: rgba(0, 128, 0, .075);
    border: 1px solid green;
    border-radius: 15px;
    font-size: 14px;
    padding: 2px 8px 4px;
    font-weight: 600;
}