.page-app-settings-container {
    width: 100%;
    height: calc(100vh - 65px);
    flex-direction: column;
    display: flex;
    overflow: hidden;  
    overflow-y: auto;
}

.page-app-settings-item {
    width: 100%;
    height: 100%;
}

.settings-options-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.settings-options-container-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 700px;
}