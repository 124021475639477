.sale-copy-id-successful {
    padding: 8px 10px;
    margin: 15px;
    border-radius: 100px;
    cursor: pointer;
}

.sale-copy-id-successful-hover:hover {
    background-color: rgba(128, 128, 128, 0.226);
}

.sale-copy-id-successful-no-hover:active {
    background-color: rgba(128, 128, 128, 0.226);
}

.successfull-sale-button-close {
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    img {
        width: 16px;
        height: 16px;
    }

}

.successfull-sale-button-close:hover {
    box-shadow: 3px 3px 3px 2px rgba(0, 0, 0, 0.2);
}