.bank-account-to-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-user-select: text;
    -webkit-touch-callout: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.bank-account-to-error-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    .bank-account-to-error-section-div {
        display: flex;

        img {
            width: 18px;
            height: 18px;
            margin-top: 2px;
            margin-right: 5px;
        }
    }

    p {
        font-weight: bold;
    }

    a {
        padding: 8px;
        font-size: 14px;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        border: none;
        border-radius: 100px;
        cursor: pointer;

        color: white;
        background-color: var(--dark-primary);
        box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    }

    a:hover {
        background-color: #3f5266;
    }

}

.bank-account-to-first-section {
    width: 100%;
    max-width: 450px;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.bank-account-to-bank-logo-desp {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bank-account-to-bank-logo {

    img {
        width: 40px;
    }
}

.bank-account-to-bank-copy-cont {
    button {
        padding: 12px;
        width: 70px;
        border: none;
        border-radius: 100px;
        cursor: pointer;

        color: white;
        background-color: var(--dark-primary);
        box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    }

    button:hover {
        background-color: #3f5266;
    }
}

.bank-account-to-p-title {
    font-size: 12px;
}

.bank-account-to-second-section {
    width: 100%;
    flex-direction: column;
    max-width: 500px;
    margin-top: 30px;
    display: flex;

    p {
        padding: 0;
        margin: 0;
    }

    hr {
        margin: 10px 0 10px 0;
        border-color: rgba(128, 128, 128, 0.253);
    }
}

.bank-account-to-title {
    padding-right: 10px;
}

.bank-account-to-type {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.bank-account-to-currency {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.bank-account-to-name {
    display: flex;
    align-items: center;
    margin-top: 8px;
    justify-content: space-between;
}

.bank-account-to-cedula {
    display: flex;
    align-items: center;
    margin-top: 8px;
    justify-content: space-between;
}

.bank-account-to-bank-upload-butt {
    display: flex;
    border: none;
    height: 45px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    color: white;
    background-color: var(--dark-primary);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);

    img {
        width: 25px;
        padding: 5px;
        margin-right: 10px;
    }

    p {
        padding: 8px;
        margin-left: 10px;
    }

    input { 
        display: none;
    }
}

.bank-account-to-bank-upload-butt:hover {
    background-color: #3f5266;
}

.bank-account-to-third-section {
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 10px 0;
    flex-direction: column;

    .bank-account-to-third-section-voucher-p {
        padding: 30px 0 50px 0;

        img {
            width: 14px;
            margin-right: 5px;
            height: 14px;
        }
    }
}

.bank-account-individual-product-yes-img-big-cont {
    display: flex;
    width: 100%;
    height: 150px;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 10px;
}

.bank-account-ind-prod-yes-img-big-con-div {
    width: 100%;
    max-width: 300px;
    height: 150px;
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    cursor: pointer;
}

.bank-account-ind-prod-yes-img-big-con-div:hover {
    img {
        opacity: 0.8;
    }
}

.bank-account-individual-product-yes-img-big {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.bank-account-individual-product-no-img-big-cont {
    display: flex;
    width: 100%;
    height: 150px;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 10px;
}

.bank-account-ind-prod-no-img-big-con-div {
    width: 100%;
    max-width: 300px;
    height: 150px;
    background-color: rgba(128, 128, 128, 0.171);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bank-account-individual-product-no-img-big {
    width: 80px;
    height: 80px;
    border-radius: 0px;
}