.customer-sub-section-main-cont {
    display: flex;
    width: 100%;
    justify-content: center;
}

.customer-sub-section-cont {
    border-bottom-style: solid;
    border-bottom-width: 0.14em;
    border-bottom-color: rgba(128, 128, 128, 0.137);
    box-shadow: 1px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    padding: 3px;
    margin-top: 10px;
    width: calc(100% - 40px);
    max-width: 1000px;
}

.customer-sub-section-div {
    display: flex;
    width: 100%;
    justify-content: center;
    max-width: 1043px;
}

.customer-sub-section-title1 {
    display: flex;
    width: 100%;
    justify-content: center;
}

.customer-sub-section-button1 {
    padding: 7px;
    width: 50%;
    border-radius: 12px 0 0 12px;
    border: none;
    white-space: nowrap;
    overflow: hidden;
}

.customer-sub-section-button2 {
    padding: 8px;
    width: 50%;
    border-radius: 0 12px 12px 0;
    border: none;
    white-space: nowrap;
    overflow: hidden;
}

.customer-sub-section-title2 {
    padding: 5px 15px;
    font-weight: bold;
    font-size: 14px;
}

.customer-sub-section-button-active {
    cursor: pointer;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #62789027;
    border: 2.5px solid #62789085;
    border-left: 1.5px solid #62789085;
    border-right: 1.5px solid #62789085;
    font-weight: bold;
}

.customer-sub-section-button-inactive {
    cursor: pointer;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: hsla(0, 0%, 50%, 0.103);
    border: 2.5px solid transparent;
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
}

.customer-sub-section-button-inactive:hover {
    background-color: #62789027;
}
