.navigation-place-space {
    width: 100%;
    height: 65px ; //Same as navigation-container
}

.navigation-container {
    display: flex;
    position: fixed;
    justify-content: right;
    align-items: center;
    height: 65px;
    width: 100%;
}

.navigation-container-space {
    flex-grow: 1;
}

.navigation-container::selection { 
    background: none 
}

.icon-navigation {
    width: 18px;
    height: 18px;
}

.user-picture {
    width: 35px;
    height: 35px;
}

.close-button {
    width: 14px;
    height: 14px;
}

.close-button-notificatons {
    width: 14px;
    height: 14px;
}
