.settings-options-container-page-change {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.settings-options-container-item-page-change {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 850px;
}

.page-change-confirm-change-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.page-change-confirm-change-card {
    width: 100%;
    max-width: 450px;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 40px;
}

.page-change-confirm-change-card-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 70px;
        margin: 10px;
    }

    p {
        margin: 10px;
    }
}

.page-change-confirm-change-card-amount {
    display: flex;
    align-items: center;
    width: calc(100% - 10px);
    justify-content: space-between;
    padding: 5px 0 5px 0;
    
}

.page-change-confirm-change-card-amount-hr {
    border-bottom-style: dotted;
    border-bottom-color: rgba(128, 128, 128, 0.486);
    margin: 20px 0px 20px 0px;
}

.page-change-reactivate-link-div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.page-change-reactivate-link {
    text-decoration: none;
    width: 250px;
}

.page-change-confirm-change-button-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.page-change-confirm-change-button {
    padding: 10px;
    border-radius: 10px;
    border: none;
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    width: 200px;
}

.page-change-plan-cards-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}